import { Integrations } from "@sentry/tracing"

export default {
  api: "/api",
  sentry: {
      dsn: "https://69be9cfe9f9b4798ba5d79ebc29042ff@o329238.ingest.sentry.io/6766465",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  },
}
