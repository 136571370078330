import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import useFetch from "hooks/useFetch"
import { useForm } from "react-hook-form"
import { useParams } from "react-router"
import PageTemplate from "templates/PageTemplate"
import Button from "components/common/Button"
import {
  getValuationStaff,
  assignValuation,
  makeValuationOffer,
  getStaffValuationNotes,
} from "services/valuationsService"
import ErrorMessage from "components/core/ErrorMessage"
import TextField from "components/common/TextField"
import SummaryCar from "./components/SummaryCar"
import SummaryCustomer from "./components/SummaryCustomer"
import styled from "styled-components"
import CheckboxWithLabel from "./../../admin/components/common/CheckboxWithLabel"
import SummaryBar from "./components/SummaryBar"
import ValuationNotes from "./components/ValuationNotes"
import vars from "styles/variables"
import RoundedForm from "./components/RoundedForm"
import Select from "components/common/Select"
import AutotraderValuation from "./components/AutotraderValuation"

function ValuationStaffPage() {
  const { slug } = useParams()
  const [isSaving, setIsSaving] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [staffAccepting, setStaffAccepting] = useState(null)
  const [standardResponse, setStandardResponse] = useState("")
  const [notesReloads, setNotesReloads] = useState(0)
  const { data, setData, isLoading, error } = useFetch(() =>
    getValuationStaff(slug)
  )
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
  })

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  const onChoosingStaffAccepting = answer => {
    setConfirmation(false)
    setStaffAccepting(answer)
  }

  const handleAssigneeChange = assignedToId => {
    const newData = { ...data }
    newData.assignedToId = assignedToId
    setData(newData)
    assignValuation(slug, assignedToId)
  }

  const handleStandardResponse = response => {
    setStandardResponse(response)
    setValue("offerComment", response)
  }

  async function onSubmit(formData) {
    setIsSaving(true)
    const response = await makeValuationOffer({
      ...formData,
      guid: data.guid,
      staffAccepted: staffAccepting,
    })

    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response) //Move to hook
    }
    const newData = { ...data }
    newData.staffAccepted = staffAccepting
    newData.offerAmount = formData.offerAmount
    newData.offerComment = formData.offerComment
    setData(newData)
    setIsSaving(false)
    setTimeout(() => setNotesReloads(notesReloads + 1), 1000)
  }
  function handleError(error) {
    //responseError(error)
    setIsSaving(false)
  }
  //End of Submit Form

  //EVENTS END

  const customerResponse = !data
    ? null
    : data.staffAccepted !== null && (
        <Row>
          <Col xs="12" className="mt-4">
            {data.staffAccepted ? (
              <>
                <strong style={{ color: vars.orange }}>Offered: </strong>
                <span>£{data.offerAmount}</span>
                {data.offeredBy && (
                  <div>
                    {data.offeredBy} | {data.staffRespondedOn}
                  </div>
                )}
              </>
            ) : (
              <>
                <div>
                  <strong>Reject Reason:</strong>
                </div>
                <div dangerouslySetInnerHTML={{ __html: data.offerComment }} />
                <div>
                  <strong>{data.offeredBy}</strong> {data.staffRespondedOn}
                </div>
              </>
            )}
          </Col>
          {data.customerAccepted !== null && (
            <Col xs="12" className="mt-3">
              <SummaryBar
                alt={1}
                fullWidth
                value={
                  (data.customerAccepted === true
                    ? "Customer accepted on "
                    : "Customer rejected the offer on ") +
                  data.customerRespondedOn +
                  ("<br /><br />" + (data.customerResponseComment || ""))
                }
                isHtml
              />
            </Col>
          )}
        </Row>
      )

  const makeOfferForm = !data
    ? null
    : data.staffAccepted === null && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs="12" className="mt-2">
              <h2>Make a Decision</h2>
            </Col>
            <Col xs="6">
              <Button
                id="MakeOffer"
                onClick={() => onChoosingStaffAccepting(true)}
                isLoading={isSaving}
                isFullWidth
              >
                Make an Offer
              </Button>
            </Col>

            <Col xs="6">
              <Button
                id="Reject" //match
                outline
                onClick={() => onChoosingStaffAccepting(false)}
                isLoading={isSaving}
                isFullWidth
              >
                Reject Car
              </Button>
            </Col>
          </Row>
          {staffAccepting === true ? (
            <Row className="mt-4">
              <Col>
                <TextField
                  key="offerAmount"
                  name="offerAmount"
                  autoFocus
                  required
                  control={control}
                  label="Offer Amount"
                  type="number"
                  error={formState.errors.offerAmount}
                />
              </Col>
            </Row>
          ) : staffAccepting === false ? (
            <Row>
              <Col xs="12" className="mt-3">
                <Select
                  control={control}
                  label="Reason for Rejecting"
                  name="standardResponse"
                  error={formState.errors.assignedTo}
                  value={standardResponse || ""}
                  options={data.standardResponses}
                  onChange={e => handleStandardResponse(e.target.value)}
                />
              </Col>
              <Col className="mt-1">
                <TextField
                  key="offerComment"
                  name="offerComment"
                  //defaultValue={data.offerComment}
                  autoFocus
                  multiline
                  required
                  control={control}
                  label="Custom Reason"
                  error={formState.errors.offerComment}
                />
              </Col>
            </Row>
          ) : null}
          {staffAccepting !== null && (
            <Row>
              <Col xs="12">
                <CheckboxWithLabel
                  name="confirmation"
                  label="I have read all of the above information." //match
                  control={control}
                  onChange={e => setConfirmation(e.target.checked)}
                  className=""
                  checked={confirmation}
                />
              </Col>
              <Col xs="12">
                <Button
                  styleType="green"
                  type="submit"
                  isFullWidth
                  isLoading={isSaving}
                  disabled={!confirmation}
                  style={{ opacity: confirmation ? "1" : "0.5" }}
                >
                  Submit Offer
                </Button>
              </Col>
            </Row>
          )}
        </form>
      )

  return (
    <>
      <PageTemplate
        title="Valuation Admin"
        content="Content"
        hideBorders
        adminFooter
      >
        <RoundedForm fullWidth>
          {isLoading && <ValuationStaffLoadingSkeleton />}
          {!isLoading && (
            <Row className="mt-4">
              <Col xs="12" md="6">
                <h2>Customer's Car</h2>
                <SummaryCar isLoading={isLoading} data={data} />
                <AutotraderValuation guid={slug} />
              </Col>
              {/* Start of right column */}
              <Col xs="12" md="6">
                <h2>Customer's Details</h2>
                <SummaryCustomer isLoading={isLoading} data={data} />
                <Row>
                  <Col className="mt-4">
                    <StyledSectionHeader>Assign This Lead</StyledSectionHeader>
                    <Select
                      control={control}
                      defaultText="Assign to"
                      name="assignedTo"
                      error={formState.errors.assignedTo}
                      value={data.assignedToId || ""}
                      options={data.assignees}
                      onChange={e => handleAssigneeChange(e.target.value)}
                    />
                  </Col>
                </Row>

                <ValuationNotes
                  guid={slug}
                  telephone={data.telephone}
                  mainNoteType={vars.noteType_internal}
                  get={getStaffValuationNotes}
                  forceReload={notesReloads}
                />
                {makeOfferForm}
              </Col>
            </Row>
          )}
        </RoundedForm>
      </PageTemplate>
      <StyledFooter />
    </>
  )
}

export default ValuationStaffPage

function ValuationStaffLoadingSkeleton() {
  return (
    <SkeletonTheme baseColor={vars.grey_600} highlightColor={vars.grey_700}>
      <Row className="mt-5">
        <Col xs="12" md="6" className="mb-4">
          <Row className="mt-3">
            <Col xs="12">
              <Skeleton height={800} className="mb-4" />
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="6">
          <Row className="mt-3">
            <Col xs="12">
              <Skeleton height={300} className="mb-4" />
            </Col>
          </Row>
        </Col>
      </Row>
    </SkeletonTheme>
  )
}

const StyledFooter = styled.footer`
  height: 3rem;
`

const StyledSectionHeader = styled.div`
  padding: 10px 0;
  color: ${vars.orange};
  font-weight: bold;
  margin-top: 1rem;
`
