import React from "react"
import styled from "styled-components"
import vars from "styles/variables"

function NeedHelp({ topMargin = true }) {
  return (
    <>
      <div className={"pb-2 " + (topMargin ? "mt-4" : "")}>
        Need help?
        <StyledLink href="tel:01912661081">Call 0191 2661081</StyledLink>
      </div>
    </>
  )
}

export default NeedHelp

const StyledLink = styled.a`
  padding-left: 1rem;
  color: ${vars.orange} !important;
  font-weight: bold;
`
