import * as React from "react"

const ArrowRightIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 44"
    style={{
      enableBackground: "new 0 0 44 44",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <circle
      cx={22}
      cy={22}
      r={21.5}
      style={{
        fill: "#222",
        stroke: "#222",
      }}
    />
    <path
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#fa0",
      }}
      d="M24.4 16 23 17.4l3.2 3.3H14v2h12.5L23.1 26l1.3 1.3 4.3-4.2 1.4-1.4z"
    />
  </svg>
)

export default ArrowRightIcon
