import React from "react"
import styled from "styled-components"

function BenefitsList() {
  return (
    <StyledBenefitsList>
      <StyledBenefit>We will settle any outstanding finance</StyledBenefit>
      <StyledBenefit>We can collect from your work or home</StyledBenefit>
      <StyledBenefit>We will pay by bank transfer same day</StyledBenefit>
    </StyledBenefitsList>
  )
}

export default BenefitsList

const StyledBenefitsList = styled.ul`
  list-style-image: url("/images/bullet-tick.png");
  margin: 5px;
  line-spacing: 10px;
  padding: 12px;
`

const StyledBenefit = styled.li`
  margin: 10px 0;
  font-weight: bold;
`
