import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import GlobalStyles from "styles/globalStyles"

function Layout({ children }) {
  const { pathname } = useLocation()

  //* Scrolls the user to the top on every page transition
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Remove layout for admin area, it has its own
  if (/\/admin/gi.test(pathname)) return children

  return (
    <LayoutContainer>
      <Helmet></Helmet>
      <GlobalStyles />
      {/* <Header /> */}
      <main>{children}</main>
    </LayoutContainer>
  )
}

export default Layout

const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
