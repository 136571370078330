import config from "config"
import http from "services/httpService"

const apiEndpoint = `${config.api}/Valuations`

//Services

export async function getValuationIntro() {
  const data = await http.get(apiEndpoint + "/getvaluationintro", {
    params: {},
  })
  return data
}
//End of Get

export async function saveValuationIntro(form) {
  const data = await http.post(apiEndpoint + "/savevaluationintro", form)
  return data
}
//End of Post

export async function getValuationCar() {
  const data = await http.get(apiEndpoint + "/getvaluationcar", {
    params: {},
  })
  return data
}
//End of Get

export async function saveValuationCar(form) {
  const data = await http.post(apiEndpoint + "/savevaluationcar", form)
  return data
}
//End of Post

export async function getValuationCarExtra() {
  const data = await http.get(apiEndpoint + "/getvaluationcarextra", {
    params: {},
  })
  return data
}
//End of Get

export async function saveValuationCarExtra(form) {
  const data = await http.post(apiEndpoint + "/savevaluationcarextra", form)
  return data
}
//End of Post

export async function getValuationAboutYou() {
  const data = await http.get(apiEndpoint + "/getvaluationaboutyou", {
    params: {},
  })
  return data
}
//End of Get

export async function saveValuationAboutYou(form) {
  const data = await http.post(apiEndpoint + "/savevaluationaboutyou", form)
  return data
}
//End of Post

export async function getValuationImages() {
  const data = await http.get(apiEndpoint + "/getvaluationimages", {
    params: {},
  })
  return data
}
//End of Get

export async function uploadValuationImage(form) {
  const data = await http.post(apiEndpoint + "/uploadvaluationimage", form)
  return data
}

export async function getValuationOffer(guid) {
  const data = await http.get(apiEndpoint + "/getvaluationoffer", {
    params: { guid: guid },
  })
  return data
}
//End of Get

export async function getValuationStaff(guid) {
  const data = await http.get(apiEndpoint + "/getvaluationstaff", {
    params: { guid: guid },
  })
  return data
}
//End of Get

export async function makeValuationOffer(form) {
  const data = await http.post(apiEndpoint + "/makevaluationoffer", form)
  return data
}

export async function acceptValuationOffer(form) {
  const data = await http.post(apiEndpoint + "/acceptvaluationoffer", form)
  return data
}

export async function getLeadsMaterialTable(query) {
  const orderByField = query.orderBy?.field || null
  const { data } = await http.post(
    apiEndpoint + "/leadsmaterialtable",
    {
      ...query,
      ...orderByField,
    },
    null,
    true
  )
  return data
}

export async function getCustomerValuationNotes(guid) {
  const data = await http.get(apiEndpoint + "/getcustomervaluationnotes", {
    params: { guid: guid },
  })
  return data
}
export async function getStaffValuationNotes(guid) {
  const data = await http.get(apiEndpoint + "/getstaffvaluationnotes", {
    params: { guid: guid },
  })
  return data
}

export async function assignValuation(guid, assignedToId) {
  const data = await http.post(apiEndpoint + "/assignvaluation", {
    guid,
    assignedToId,
  })
  return data
}

export async function addValuationNote(form) {
  const data = await http.post(apiEndpoint + "/addvaluationnote", form)
  return data
}
//End of Post

export async function getAutotraderValuation(guid) {
  const data = await http.get(apiEndpoint + "/autotradervaluation", {
    params: { guid: guid },
  })
  return data
}
//End of Get

//Services end
