import * as React from "react"
import styled from "styled-components"
import vars from "styles/variables"

const LinkedInIcon = ({ rest }) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86 10.565H8.254a.129.129 0 0 0-.129.129v5.16c0 .07.058.128.129.128H9.86a.129.129 0 0 0 .13-.129v-5.159a.129.129 0 0 0-.13-.13ZM9.058 8a1.06 1.06 0 0 0-1.06 1.059 1.06 1.06 0 0 0 2.119 0A1.06 1.06 0 0 0 9.057 8ZM13.945 10.437c-.645 0-1.121.277-1.41.592v-.335a.129.129 0 0 0-.13-.13h-1.538a.129.129 0 0 0-.129.13v5.16c0 .07.058.128.13.128h1.602a.129.129 0 0 0 .129-.129v-2.552c0-.86.233-1.196.833-1.196.653 0 .705.537.705 1.24v2.508c0 .072.058.13.13.13h1.602a.129.129 0 0 0 .13-.13v-2.83c0-1.279-.245-2.586-2.054-2.586Z"
      fill="#fff"
    />
    <circle cx={12} cy={12} r={11.5} stroke="#fff" />
  </svg>
)

export default LinkedInIcon
