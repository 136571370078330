//Locked
import React from "react"
import { Col, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"

//Simply a piece of text with either a black or grey background - alt={1} means grey

function SummaryBar({ label, value, alt, fullWidth = false, isHtml = false }) {
  return (
    <StyledSummaryBar alt={alt}>
      <Row>
        <Col
          xs={fullWidth ? "12" : "6"}
          className={"font-weight-bold" + (fullWidth ? " mb-2" : "")}
        >
          {label}
        </Col>
        <Col
          xs={fullWidth ? "12" : "6"}
          className={fullWidth ? "" : "text-truncate"}
        >
          {isHtml ? (
            <div dangerouslySetInnerHTML={{ __html: value }} />
          ) : (
            <span>{value}</span>
          )}
        </Col>
      </Row>
    </StyledSummaryBar>
  )
}

export default SummaryBar

const StyledSummaryBar = styled.div`
  padding: 10px;
  background-color: ${props =>
    props.alt === 1 ? vars.lighterBackground : ""} !important;
`
