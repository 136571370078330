import TagManager from "react-gtm-module"
import ReactPixel from 'react-facebook-pixel';
import httpService from "services/httpService"

export async function initTagManager() {
  const data = await httpService.get(`/api/Home/TagManagerCode`)

  TagManager.initialize({
    gtmId: data.gtmCode,
  })

  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(data.pixelId, advancedMatching, options);
  ReactPixel.pageView();
}
