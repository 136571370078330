function error(error) {
  console.error(error)
}

function info(message, obj) {
  console.log(message, obj)
}

export default {
  error,
  info,
}
