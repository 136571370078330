//Locked
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, Col, Row } from "reactstrap"
import useFetch from "hooks/useFetch"
import { useForm } from "react-hook-form"
import { useParams } from "react-router"
import PageTemplate from "templates/PageTemplate"
import Button from "components/common/Button"
import {
  getValuationIntro,
  saveValuationIntro,
} from "services/valuationsService"
import ErrorMessage from "components/core/ErrorMessage"
import TextField from "components/common/TextField"
import IntroBenefits from "components/bye-buy-motors/IntroBenefits"
import IntroBenefits2 from "components/bye-buy-motors/IntroBenefits2"
import PreviousCustomers from "components/bye-buy-motors/PreviousCustomers"
import LocationsCovered from "components/bye-buy-motors/LocationsCovered"
import NeedHelp from "components/bye-buy-motors/NeedHelp"
import styled from "styled-components"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import vars from "styles/variables"
import Social from "components/core/Social"

function ValuationIntroPage() {
  const history = useHistory()
  const { slug } = useParams()
  const [isSaving, setIsSaving] = useState(false)
  const [warning, setWarning] = useState(null)
  const { data, isLoading, error } = useFetch(() => getValuationIntro(slug))
  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  })

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  async function onSubmit(formData) {
    console.log(formData)

    if (parseInt(formData.mileage) >= 100000) {
      setWarning(
        "Sorry but we only buy cars with under 100,000 miles on the clock."
      )
      return
    }

    setIsSaving(true)
    const response = await saveValuationIntro({ ...data, ...formData })

    //if (!isMounted.current) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response) //Move to hook
    }
    setIsSaving(false)
    history.push("/valuation/car")
  }
  function handleError(error) {
    console.log("ERROR", error)
    //responseError(error)
    //if (!isMounted.current) return
    setIsSaving(false)
  }
  //End of Submit Form

  //EVENTS END

  return (
    <PageTemplate title="Valuation Intro" content="Content" hideBorders>
      <Container fluid>
        <Row>
          {/* Top left grey column to simulate a container */}

          <Col
            xs="hidden"
            sm="hidden"
            md="hidden"
            lg="1"
            xl="2"
            style={{ backgroundColor: vars.grey_300 }}
          ></Col>

          {/* Left grey section with benefits */}

          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="4"
            style={{ backgroundColor: vars.grey_300 }}
            className="pr-0"
          >
            <IntroBenefits />
          </Col>

          {/* Right black section with form */}

          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="4"
            style={{ backgroundColor: vars.black }}
            className="pl-0"
          >
            <StyledFormContainer>
              <a name="value-vehicle"></a>
              <StyledForm>
                <Row>
                  <Col xs="1" lg="2"></Col>
                  <Col xs="10" lg="8">
                    <div style={{ float: "right" }}>
                      <Social />
                    </div>
                    <StyledFormHeading>Value your car</StyledFormHeading>
                    {isLoading && <ValuationIntroLoadingSkeleton />}
                    {!isLoading && (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          {/* RENDER */}
                          <Col xs="12">
                            <TextField
                              autoFocus
                              required
                              control={control}
                              label="Registration Number*"
                              placeHolder="Enter Reg Number"
                              name="registrationNumber" //Match
                              error={formState.errors.registrationNumber}
                              defaultValue={data.registrationNumber || ""}
                            />
                          </Col>
                          {/* End of Text control */}

                          <Col xs="12 pb-0 mb-0">
                            <TextField
                              required
                              type="number"
                              control={control}
                              label="Mileage*"
                              sublabel="(we only buy cars with under 100k miles on the clock)"
                              placeHolder="Enter Mileage"
                              name="mileage" //Match
                              error={formState.errors.mileage}
                              defaultValue={
                                data.mileage > 0 ? data.mileage + "" : ""
                              }
                            />
                          </Col>
                          {/* End of Integer control */}

                          {warning && (
                            <Col
                              xs="12"
                              className="mt-0 pt-0 mb-4"
                              style={{ color: vars.red }}
                            >
                              {warning}
                            </Col>
                          )}

                          <Col xs="12">
                            {/* Match */}
                            <Button
                              id="ValueMyCar"
                              type="submit"
                              isLoading={isSaving}
                              isFullWidth={true}
                              isLarge={true}
                            >
                              VALUE MY CAR
                            </Button>
                          </Col>
                          {/* End of Button */}

                          {/* RENDER END */}
                        </Row>
                      </form>
                    )}
                    <NeedHelp />
                  </Col>
                  <Col xs="1" lg="2"></Col>
                </Row>
              </StyledForm>
            </StyledFormContainer>
          </Col>

          {/* Top right black column to simulate a container */}

          <Col
            xs="hidden"
            sm="hidden"
            md="hidden"
            lg="1"
            xl="2"
            style={{ backgroundColor: vars.black }}
          ></Col>
        </Row>

        <Row style={{ backgroundColor: "red" }}>
          <Col xs="12" md="6" className="pr-lg-0 pr-md-0">
            <Container>
              <Row>
                <Col xs="12"></Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Row style={{ backgroundColor: vars.orange }}>
          <Col xs="12">
            <Container>
              <Row>
                <Col xs="12">
                  <IntroBenefits2 />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Row style={{ backgroundColor: vars.white }}>
          <Col xs="12">
            <Container>
              <Row>
                <Col xs="12">
                  {/* <SliderTest /> */}
                  <PreviousCustomers />
                </Col>
                <Col xs="12">
                  <LocationsCovered />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </PageTemplate>
  )
}

export default ValuationIntroPage

function ValuationIntroLoadingSkeleton() {
  return (
    <SkeletonTheme baseColor={vars.grey_600} highlightColor={vars.grey_700}>
      <Row>
        <Col xs="12" style={{ height: "20px" }}></Col>
        <Col xs="12" className="mb-5">
          <Skeleton height={50} />
        </Col>
        <Col xs="12" className="mb-5 mt-3">
          <Skeleton height={50} />
        </Col>
      </Row>
    </SkeletonTheme>
  )
}

const StyledFormContainer = styled.div`
  background-color: ${vars.grey_300};
  padding-left: 0;
`
const StyledForm = styled.div`
  background-color: ${vars.black};
  border: 2px solid ${vars.black};
  border-top-left-radius: 40px;
  padding: 10px 0px 30px 0px;
`
const StyledFormHeading = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin: 20px 0;
`
