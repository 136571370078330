import React from "react"
import Button from "admin/components/common/Button"
import { useHistory } from "react-router-dom"

//Button that goes back to whatever the previous page is

function BackButton({ children }) {
  const history = useHistory()

  return (
    <Button
      onClick={() => history.goBack()}
      variant="outlined"
      color="secondary"
    >
      {children}
    </Button>
  )
}

export default BackButton
