import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import vars from "styles/variables"

function FooterAdmin() {
  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col xs="auto">
            <p
              className="font-weight-bold"
              style={{ fontSize: ".7rem", color: vars.grey_400 }}
            >
              <a href="/valuation/leads">Back to Leads</a>
            </p>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  )
}

export default FooterAdmin

const StyledFooter = styled.footer`
  margin-top: 0rem;
  padding: 1.8rem 0;
  background: ${vars.black};
  color: ${vars.white};

  a {
    color: ${vars.white};
  }
`
const StyledSmallPrint = styled.span`
  font-size: 0.7rem;
`
