import React from "react"
import FacebookIcon from "components/svg/FacebookIcon"
import LinkedInIcon from "components/svg/LinkedInIcon"
import TwitterIcon from "components/svg/TwitterIcon"

function Social({ size = 20 }) {
  return (
    <>
      <a
        className="mr-2"
        href="https://www.facebook.com/byebuymotors/"
        target="facebook"
      >
        <FacebookIcon size={size} />
      </a>
      <a
        className="mr-2"
        href="https://www.linkedin.com/company/redgate-lodge-limited"
        target="linkedin"
      >
        <LinkedInIcon size={size} />
      </a>
      <a
        className="mr-2"
        href="https://twitter.com/redgatelodge"
        target="twitter"
      >
        <TwitterIcon size={size} />
      </a>
    </>
  )
}

export default Social
