import React from "react"
import { default as MdcButton } from "@material-ui/core/Button"
import { Link } from "react-router-dom"

function Button({
  children,
  isLoading,
  type,
  to,
  color = "primary",
  variant = "contained",
  disabled,
  ...rest
}) {
  // Form Button
  if (!to)
    return (
      <MdcButton
        type={type}
        color={color}
        variant={variant}
        disabled={isLoading ? true : disabled}
        {...rest}
      >
        {children}
      </MdcButton>
    )

  // Internal link
  // If the 'to' property starts with exactly one slash this is an internal link.
  const isInternal = /^\/(?!\/)/.test(to)
  if (isInternal)
    return (
      <MdcButton
        to={to}
        component={Link}
        color={color}
        variant={variant}
        disabled={disabled}
        {...rest}
      >
        {children}
      </MdcButton>
    )

  // External link
  return (
    <MdcButton
      href={to}
      color={color}
      variant={variant}
      disabled={disabled}
      {...rest}
    >
      {children}
    </MdcButton>
  )
}

export default Button
