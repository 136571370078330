import React, { forwardRef } from "react"
import styled from "styled-components"
import { Controller } from "react-hook-form"
import { Col, Row } from "reactstrap"
import vars from "styles/variables"
import ArrowDownIcon from "components/svg/ArrowDownIcon"

/* --- EXAMPLE ---
  <Select
    required
    control={control}
    label="Your Label*"
    name="fieldName"
    defaultText="Select an option"
    error={formState.errors.fieldName}
    options={yourOptions.map(x => {
      return { text: x.displayName, value: x.id }
    })}
  />
*/

const Select = forwardRef((props, ref) => {
  const {
    control,
    label,
    error,
    defaultValue,
    defaultText = "Select",
    name,
    rules,
    required,
    options,
    margin = "0 0 1.5rem 0",
    ...rest
  } = props

  return (
    <StyledSelectComponent margin={margin}>
      <Row className="justify-content-between">
        {label && (
          <Col xs="auto">
            <label
              className="mb-1 d-inline-block"
              style={{ fontWeight: "bold" }}
            >
              {label}
            </label>
          </Col>
        )}
        {error && (
          <Col xs="auto" className="text-danger mb-1">
            <div className="overflow-hidden">
              <div className="animate-slide-in-right">
                {error.type === "required" ? "Required" : error.message}
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required, ...rules }}
        render={({ field: { onChange, onBlur, value } }) => (
          <StyledSelectContainer error={error}>
            <StyledSelect
              ref={ref}
              name={name}
              onChange={value => onChange(value)}
              value={value}
              error={error}
              className={`${value ? "" : "deselected"}`}
              {...rest}
            >
              <option value="">{defaultText}</option>
              {options?.map((option, index) => (
                <option key={index} value={option.value || option}>
                  {option.text || option}
                </option>
              ))}
            </StyledSelect>
            <ArrowDownIcon />
          </StyledSelectContainer>
        )}
      />
    </StyledSelectComponent>
  )
})

export default Select

const StyledSelectComponent = styled.div`
  margin: ${props => props.margin};
`

const StyledSelectContainer = styled.div`
  position: relative;
  background: ${vars.white};

  svg {
    position: absolute;
    top: calc(50% - 12px);
    right: 8px;
    z-index: 15;
    font-size: 1.4rem;
    transition: ease-in-out 300ms;

    .svg-fill {
      ${props => props.error && `fill:${vars.red} !important;`}
      transition: ease-in-out 300ms;
    }
  }
`

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  cursor: pointer;
  appearance: none;
  position: relative;
  z-index: 20;
  background: transparent;
  border: 1px solid
    ${props => (props.error ? `${vars.red} !important` : vars.black)};
  color: ${props => (props.error ? `${vars.red} !important` : vars.black)};
  transition: ease-in-out 300ms;
  height: 50px;
  font-family: ${vars.primaryFont};
  font-size: 1rem;

  &:focus {
    border-color: ${vars.primary};

    + svg .svg-fill {
      fill: ${vars.primary};
    }
  }

  option {
    color: ${vars.black};
  }

  &.deselected,
  option[value=""] {
    color: ${vars.grey_500};
  }
`
