import * as React from "react"

const ArrowLeftIcon = props => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={22}
      cy={22}
      r={21.5}
      transform="rotate(-180 22 22)"
      fill="#222"
      stroke="#222"
    />
    <path fill="#222" d="M34 34H10V10h24z" />
    <path fill="#FA0" d="M30 23.344H15v-2h15z" />
    <path fill="#FA0" d="M15.343 20.93 21 26.585 19.585 28l-5.656-5.657z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m13.929 22.343 5.657-5.657 1.271 1.271-5.657 5.657-1.271-1.271Z"
      fill="#FA0"
    />
  </svg>
)

export default ArrowLeftIcon
