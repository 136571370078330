//imports
import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import loadable from "@loadable/component"
import FAQPage from "pages/faqs/FAQPage"
import ForbiddenPage from "pages/error/ForbiddenPage"
import LoadingIcon from "components/common/LoadingIcon"
import LeadsTablePage from "pages/valuations/LeadsTablePage" //End of Page import
import ValuationIntroPage from "pages/valuations/ValuationIntroPage" //End of Page import
import ValuationCarPage from "pages/valuations/ValuationCarPage" //End of Page import
import ValuationCarExtraPage from "pages/valuations/ValuationCarExtraPage" //End of Page import
import ValuationAboutYouPage from "pages/valuations/ValuationAboutYouPage" //End of Page import
import ValuationImagesPage from "pages/valuations/ValuationImagesPage" //End of Page import
import ValuationOfferPage from "pages/valuations/ValuationOfferPage" //End of Page import
import ValuationStaffPage from "pages/valuations/ValuationStaffPage" //End of Page import

//imports end

const AdminRouting = loadable(() => import("components/core/AdminRouting"), {
  fallback: <LoadingIcon colour="primary" width={120} isFullScreen />,
})

function Routing() {
  return (
    <Switch>
      {/* Routes */}
      <Route path="/admin" component={AdminRouting} />
      <Route exact path="/forbidden" component={ForbiddenPage} />
      <Route exact path="/faqs" component={FAQPage} />

      <Route path="/valuation/car" component={ValuationCarPage} />

      <Route path="/valuation/extra" component={ValuationCarExtraPage} />

      <Route path="/valuation/leads" component={LeadsTablePage} />

      <Route path="/valuation/you" component={ValuationAboutYouPage} />

      <Route path="/valuation/images" component={ValuationImagesPage} />

      <Route path="/valuation/offer/:slug" component={ValuationOfferPage} />

      <Route path="/valuation/offer/:slug" component={ValuationOfferPage} />

      <Route path="/valuation/staff/:slug" component={ValuationStaffPage} />

      <Route exact path="/" component={ValuationIntroPage} />

      {/* Routes end */}
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routing
