import React from "react"
import Skeleton from "react-loading-skeleton"
import PageTemplate from "templates/PageTemplate"
import useFetch from "hooks/useFetch"
import { getFAQList } from "services/faqService"
import ErrorMessage from "components/core/ErrorMessage"
import Accordion from "components/common/Accordion"

function FAQPage() {
  const { data: faqData, isLoading, error } = useFetch(getFAQList)

  if (error) return <ErrorMessage content={error} />

  return (
    <PageTemplate
      title="FAQ's"
      content="Everything you need to know about Bye Buy Motors."
      hideBorders
    >
      <hr className="mt-4 mb-3" />
      {isLoading && <FAQLoadingSkeleton />}
      {!isLoading && <Accordion data={faqData.items} />}
    </PageTemplate>
  )
}

export default FAQPage

function FAQLoadingSkeleton() {
  let rows = []
  for (let i = 0; i < 7; i++) {
    rows.push(<Skeleton key={i} height={40} className="mb-3" />)
  }
  rows.push(<hr key="hr" className="mb-4 mt-0" />)
  return rows
}
