import React, { forwardRef } from "react"
import styled from "styled-components"
import { Controller } from "react-hook-form"
import { Col, Row } from "reactstrap"
import vars from "styles/variables"
import ArrowDownIcon from "components/svg/ArrowDownIcon"
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core"

const Radios = forwardRef((props, ref) => {
  const {
    control,
    label,
    error,
    defaultValue,
    name,
    rules,
    register,
    required,
    options,
    ...rest
  } = props

  return (
    <>
      <Row className="justify-content-between">
        {label && (
          <Col xs="auto">
            <label className="mb-1 d-inline-block">{label}</label>
          </Col>
        )}
        {error && (
          <Col xs="auto" className="text-danger mb-1">
            <div className="overflow-hidden">
              <div className="animate-slide-in-right">
                {error.type === "required" ? "Required" : error.message}
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        {options?.map((option, index) => (
          <Col key={index}>
            <StyledRadioContainer className="form-check" error={error}>
              <label
                key={index}
                htmlFor={name + "-" + option.value}
                className="form-check-label"
              >
                <input
                  className="form-check-input"
                  {...register(name, { required: true })}
                  type="radio"
                  name={name}
                  value={option.value}
                  defaultChecked={option.value === defaultValue}
                  id={name + "-" + option.value}
                />
                {option.text}
              </label>
            </StyledRadioContainer>
          </Col>
        ))}
      </Row>
    </>
  )
})

export default Radios

const StyledRadioContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;
  white-space: nowrap;
  ${props => props.error && `color:${vars.red};`}
`
