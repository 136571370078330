import config from "config"
import http from "services/httpService"

const apiEndpoint = `${config.api}/Article`

//Services

export async function getArticleList(articleType) {
  const data = await http.get(`${apiEndpoint}/ArticleList`, {
    params: { articleType },
  })
  return data
}

export async function getArticleListFiltered(articleType, currentPage = 1) {
  const data = await http.get(`${apiEndpoint}/ArticleListFiltered`, {
    params: { articleType, currentPage },
  })
  return data
}

export async function getArticleDetails(articleShortName) {
  const data = await http.get(`${apiEndpoint}/ArticleDetails`, {
    params: { articleShortName },
  })
  return data
}

export async function getVideoList() {
  const data = await http.get(`${apiEndpoint}/VideoList`)
  return data
}

export async function getPreviousCustomers() {
  const data = await http.get(apiEndpoint + "/getpreviouscustomers", {
    params: {},
  })
  return data
}
//End of Get

//Services End
