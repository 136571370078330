import React, { useRef, useState } from "react"
import { getLeadsMaterialTable } from "services/valuationsService"
import MaterialTable, { MTableToolbar } from "material-table"
import { useHistory } from "react-router-dom"
import BackButton from "admin/components/common/BackButton"
import { toast } from "react-toastify"
import PageTemplate from "templates/PageTemplate"
import vars from "styles/variables"
import { Container, Row, Col } from "reactstrap"
import CheckboxWithLabel from "admin/components/common/CheckboxWithLabel"

export default function LeadsTablePage() {
  //STATE

  const [assignedToMe, setAssignedToMe] = useState(false)

  //HOOKS

  const history = useHistory()
  const tableRef = useRef()

  //EVENTS

  const getQuery = async query => {
    var thisFilter = query.filters?.find(f => f.column.field === "assignedToMe")
    console.log("thisFilter", query, thisFilter)
    if (thisFilter) {
      thisFilter.value = assignedToMe ? "1" : "0"
    } else {
      query.filters.push({
        column: { field: "assignedToMe" },
        operator: "=",
        value: assignedToMe ? "1" : "0",
      })
    }
    console.log("query", query)
    var data = await getLeadsMaterialTable(query)
    if (data.indexOf && data.indexOf("<html>") > -1)
      window.location.href = "/Account/Login"
    if (data.responseType === "SUCCESS") return data
    toast(data.responseMessage)
  }

  const handleSetAssignedToMe = checked => {
    setAssignedToMe(checked)
    tableRef.current.onQueryChange()
  }

  //RENDER

  return (
    <PageTemplate title="Leads" content="Content" hideBorders hideFooter>
      <Container>
        <Row>
          <Col xs="12">
            <Row className="mb-4">
              <Col xs="6" className="mb-2 mt-2  ">
                <a href="/admin">
                  <img
                    src="/images/logo-white-4c.png"
                    alt="Bye Buy Motors logo"
                    title="Back to Admin"
                  />
                </a>
              </Col>
              <Col xs="6" className="mb-2 mt-2 text-right">
                <BackButton>Back</BackButton>
              </Col>
              <Col xs="12">
                <MaterialTable
                  title="Leads"
                  tableRef={tableRef}
                  options={{
                    pageSize: 10,
                    filtering: false,
                    sorting: false,
                    headerStyle: {
                      color: vars.orange,
                    },
                  }}
                  data={getQuery}
                  columns={[
                    { title: "Full Name", field: "fullName" },
                    {
                      title: "Registration Number",
                      field: "registrationNumber",
                    },
                    { title: "Model", field: "model" },
                    // { title: "Make", field: "make" },
                    {
                      title: "Request Date",
                      field: "createdOn",
                      type: "date",
                      dateSetting: { locale: "en-GB" },
                    },
                    { title: "Status", field: "status" },
                    { title: "Assigned To", field: "assignedTo" },
                  ]}
                  onRowClick={(event, rowData) => {
                    history.push(`/valuation/staff/${rowData.guid}`)
                  }}
                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: "0px 10px" }}>
                          <CheckboxWithLabel
                            name="assignedToMe"
                            label="Show My Leads"
                            onChange={e =>
                              handleSetAssignedToMe(e.target.checked)
                            }
                            className=""
                            checked={assignedToMe}
                          />
                        </div>
                      </div>
                    ),
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </PageTemplate>
  )
}
