import React from "react"
import { Col, Row } from "reactstrap"
import Button from "components/common/Button"
import styled from "styled-components"
import vars from "styles/variables"
import StepsIcon from "../svg/StepsIcon"

function IntroBenefits2Bullet({ step, text }) {
  return (
    <Col xs="12" sm="4" className="mb-3">
      <div>
        <StepsIcon step={step} />
      </div>
      <div className="font-weight-bold" style={{ fontSize: "1.2rem" }}>
        Step {step}
      </div>
      <div className="font-weight-bold">{text}</div>
    </Col>
  )
}

function IntroBenefits2() {
  return (
    <StyledBenefits>
      <Row>
        <Col xs="hidden" md="1" lg="2"></Col>
        <Col xs="12" md="10" lg="8">
          <StyledH2>Valuations tailored specifically to you</StyledH2>

          <p>
            Buy Bye is about people - not computer programs. Unlike our
            competitors we won't give you a valuation based on what a generic
            database says, only to give a completely different figure when we
            see the car. Instead, we use the knowledge of our vastly experienced
            team of specialists to tailor each valuation for you!
          </p>

          <StyledH2>Say bye to your motor in 3 easy steps</StyledH2>

          <p>
            Selling your car has never been easier! We take all the hassle away
            from your selling experience with 3 simple steps:
          </p>

          <Row className="mt-4">
            <IntroBenefits2Bullet step={1} text="Agree an accurate price" />
            <IntroBenefits2Bullet step={2} text="We collect your car" />
            <IntroBenefits2Bullet step={3} text="Same day bank transfer" />
          </Row>
        </Col>
        <Col xs="hidden" md="1" lg="2"></Col>
      </Row>

      <Row>
        <Col className="mt-2">
          <Button styleType="black" to="#value-vehicle">
            Value Your Vehicle
          </Button>
        </Col>
      </Row>
    </StyledBenefits>
  )
}

export default IntroBenefits2

const StyledH2 = styled.h2`
  color: ${vars.black};
`
const StyledBenefits = styled.div`
  padding: 30px;
  color: ${vars.black};
  background-color: ${vars.orange};
  text-align: center;
`
