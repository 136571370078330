import React from "react"
import { Col, Row } from "reactstrap"
import Skeleton from "react-loading-skeleton"
import useFetch from "hooks/useFetch"
import { useParams } from "react-router"
import { getPreviousCustomers } from "services/articleService"
import ErrorMessage from "components/core/ErrorMessage"
import styled from "styled-components"
import vars from "styles/variables"
import ArrowRightIcon from "components/svg/ArrowRightIcon"
import ArrowLeftIcon from "components/svg/ArrowLeftIcon"
import Slider from "react-slick"

function PreviousCustomers() {
  const { slug } = useParams()
  const { data, isLoading, error } = useFetch(() => getPreviousCustomers(slug))

  const slickSettings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: 0,
    speed: 500,
    slidesToShow:
      data && data.customers && data.customers.length < 3
        ? data.customers.length
        : 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  //EVENTS END

  return (
    <StyledBenefits>
      <h2 style={{ color: vars.black, textAlign: "center" }} className="mb-5">
        Take a look at some of our happy customers...
      </h2>
      {isLoading ? (
        <PreviousCustomersLoadingSkeleton />
      ) : (
        <Slider {...slickSettings}>
          {data.customers.map(customer => {
            return (
              <div key={customer.id} className="p-3">
                <div>
                  <img
                    src={customer.listImage}
                    className="img-fluid"
                    alt={"Previous customer " + customer.title}
                  />
                </div>
                <StyledText className="font-weight-bold">
                  {customer.title}
                </StyledText>
                <StyledText orange className="font-weight-bold mb-2">
                  {customer.articleShortName}
                </StyledText>
                <StyledText
                  dangerouslySetInnerHTML={{ __html: customer.body }}
                />
              </div>
            )
          })}
        </Slider>
      )}
    </StyledBenefits>
  )
}

export default PreviousCustomers

function PreviousCustomersLoadingSkeleton() {
  return (
    <Row className="mt-5">
      <Col xs="12" sm="4">
        <Skeleton height={300} />
      </Col>
      <Col xs="12" sm="4">
        <Skeleton height={300} />
      </Col>
      <Col xs="12" sm="4">
        <Skeleton height={300} />
      </Col>
    </Row>
  )
}

function SlickPrevArrow({ currentSlide, slideCount, ...rest }) {
  return <StyledLeftArrow {...rest} />
}

function SlickNextArrow({ currentSlide, slideCount, ...rest }) {
  return <StyledRightArrow {...rest} />
}

const StyledBenefits = styled.div`
  padding: 20px;
  background-color: ${vars.white};
  color: ${vars.black};
`

const StyledText = styled.div`
  font-size: 0.8rem;
  text-align: left;
  ${props => (props.orange ? `color:${vars.orange};` : "")}
`

const StyledLeftArrow = styled(ArrowLeftIcon)`
  position: absolute !important;
  top: 40% !important;
  left: -12px;
  width: 40px;
  height: 40px;
  z-index: 100 !important;
`

const StyledRightArrow = styled(ArrowRightIcon)`
  position: absolute !important;
  top: 40% !important;
  right: -12px;
  width: 40px;
  height: 40px;
  z-index: 100 !important;
`
