import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react"

const Context = createContext()

// Provider component that wraps your app and makes our object
// available to any child component
export function ValuationProvider({ children }) {
  const value = useProvider()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

// Hook for child components to get the object
// and re-render when it changes
export const useValuations = () => {
  return useContext(Context)
}

// Provider hook that creates object and handles state
function useProvider() {
  const [guid, setGuid] = useState()

  return {
    guid,
    setGuid,
  }
}
