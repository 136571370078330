import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"
import useFetch from "hooks/useFetch"
import { useForm } from "react-hook-form"
import PageTemplate from "templates/PageTemplate"
import { getValuationCar, saveValuationCar } from "services/valuationsService"
import ErrorMessage from "components/core/ErrorMessage"
import TextField from "components/common/TextField"
import Select from "components/common/Select"
import ProgressBar from "./components/ProgressBar"
import NextBackButtons from "./components/NextBackButtons"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import RoundedForm from "./components/RoundedForm"
import vars from "styles/variables"

function ValuationCarPage() {
  const history = useHistory()
  const [isSaving, setIsSaving] = useState(false)
  const { data, isLoading, error } = useFetch(() => getValuationCar())
  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  })

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  async function onSubmit(formData) {
    setIsSaving(true)
    const response = await saveValuationCar({ ...data, ...formData })

    //if (!isMounted.current) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response) //Move to hook
    }
    setIsSaving(false)
    history.push("/valuation/extra")
  }
  function handleError(error) {
    //responseError(error)
    //if (!isMounted.current) return
    setIsSaving(false)
  }
  //End of Submit Form

  //EVENTS END

  return (
    <PageTemplate title="Valuation" content="Content" hideBorders hideFooter>
      <RoundedForm>
        <ProgressBar currentStep={1} totalSteps={4}>
          About Your Car
        </ProgressBar>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {isLoading && <ValuationCarLoadingSkeleton />}
            {!isLoading && (
              <>
                <Col xs="12">
                  <TextField
                    autoFocus
                    required
                    control={control}
                    label="Make*"
                    placeholder="Enter Make"
                    name="make" //Match
                    error={formState.errors.make}
                    defaultValue={data.make || ""}
                  />
                </Col>

                <Col xs="12">
                  <TextField
                    required
                    control={control}
                    label="Model*"
                    placeholder="Enter Model"
                    name="model" //Match
                    error={formState.errors.model}
                    defaultValue={data.model || ""}
                  />
                </Col>

                <Col xs="12">
                  <TextField
                    required
                    control={control}
                    label="Colour*"
                    placeholder="Enter Colour"
                    name="colour" //Match
                    error={formState.errors.colour}
                    defaultValue={data.colour || ""}
                  />
                </Col>

                <Col xs="12">
                  <Select
                    control={control}
                    required
                    label="MOT*" //match
                    name="mot"
                    error={formState.errors.mot}
                    defaultValue={data.mot || ""}
                    options={data.motItems}
                  />
                </Col>

                <Col xs="12">
                  <Select
                    control={control}
                    required
                    label="Service History*" //match
                    name="serviceHistory"
                    error={formState.errors.serviceHistory}
                    defaultValue={data.serviceHistory || ""}
                    options={data.serviceHistoryItems}
                  />
                </Col>

                <NextBackButtons isLoading={isSaving} />
              </>
            )}
          </Row>
        </form>
      </RoundedForm>
    </PageTemplate>
  )
}

export default ValuationCarPage

function ValuationCarLoadingSkeleton() {
  return (
    <SkeletonTheme baseColor={vars.grey_600} highlightColor={vars.grey_700}>
      <Col xs="12" style={{ height: "15px" }}></Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
    </SkeletonTheme>
  )
}
