import * as React from "react"

function StepsIcon({ step, rest }) {
  // Step 1 - price icon

  if (step === 1)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        style={{
          enableBackground: "new 0 0 50 50",
          maxWidth: "200px",
        }}
        xmlSpace="preserve"
        {...rest}
      >
        <path
          d="M43.6 28.4c-.4-.9-.9-1.8-1.3-2.7-.3-.5-.3-1 0-1.5.4-.8.8-1.7 1.3-2.6 1-2 .4-3.9-1.6-4.9-.9-.5-1.7-.9-2.6-1.4-.5-.2-.8-.6-.8-1.1-.2-1-.3-1.9-.5-2.9-.3-2.1-2-3.3-3.9-3.1-.9.1-1.8.2-2.7.4-.9.2-1.5 0-2.1-.7-.6-.7-1.2-1.2-1.9-1.8-1.5-1.4-3.5-1.4-5 0-.7.7-1.5 1.4-2.2 2.1-.4.4-.8.5-1.3.4-1.1-.2-2.2-.3-3.4-.4-1.6-.2-3.1.9-3.5 2.5-.3 1.1-.4 2.2-.6 3.3-.1.6-.4 1-.9 1.3-.9.4-1.7.9-2.5 1.3-1.9 1-2.5 2.9-1.6 4.9.4.8.8 1.7 1.2 2.5.3.6.3 1.2 0 1.8-.4.8-.8 1.7-1.2 2.5-.9 1.9-.3 3.8 1.6 4.8.8.5 1.7.9 2.5 1.3.6.3.9.7 1 1.3l.6 3.3c.4 1.6 2 2.7 3.6 2.5 1-.1 2-.2 2.9-.4.8-.2 1.4 0 2 .7.4.4.9.8 1.3 1.2.7.8 1.5 1.4 2.6 1.7h1.1c1.1-.2 1.9-.9 2.6-1.7.5-.5 1.1-1 1.6-1.6.3-.3.7-.5 1.2-.4 1.1.2 2.3.3 3.4.5 1.6.2 3.2-.9 3.5-2.5.3-1.1.4-2.2.6-3.3.1-.6.4-1.1 1-1.3.9-.4 1.7-.9 2.6-1.3 1.7-.9 2.3-2.8 1.4-4.7z"
          style={{
            fill: "#222",
          }}
        />
        <path
          d="M28.6 31.9h-8.1c-.6 0-1.1-.4-1.3-1-.1-.4 0-.9.3-1.2.4-.4.7-.9.9-1.3.3-.6.5-1.4.5-2.4h-1.1c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h.5c-.3-.9-.4-1.8-.4-2.6 0-.9.2-1.7.6-2.4.4-.7 1-1.2 1.8-1.6.8-.4 1.6-.5 2.6-.5 1.6 0 2.8.4 3.7 1.3.3.3.6.7.8 1.1.5 1-.3 2.3-1.4 2.3-.6 0-1.2-.4-1.4-.9-.1-.1-.1-.3-.2-.4-.3-.3-.7-.5-1.3-.5-.5 0-1 .2-1.3.5-.3.3-.5.8-.5 1.5 0 .3 0 .6.1 1 .1.3.2.7.3 1.3h2.5c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2h-2V26c0 .5-.1 1.1-.3 1.7-.2.6-.6 1.1-1 1.5h5.7c.8 0 1.4.6 1.4 1.4 0 .7-.7 1.3-1.4 1.3z"
          style={{
            fill: "#f7a630",
          }}
        />
      </svg>
    )

  // Step 2 - finance icon

  if (step === 2)
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 50 50"
        style={{
          enableBackground: "new 0 0 50 50",
          maxWidth: "200px",
        }}
        xmlSpace="preserve"
        {...rest}
      >
        <style>{".st0{fill:#222}"}</style>
        <path
          className="st0"
          d="m30.8 7.5 7.8 7.8h-6.7c-.7 0-1.1-.4-1.1-1.1V7.5z"
        />
        <path
          className="st0"
          d="M38.9 17.5H32c-1.6 0-2.8-1-3.2-2.4-.1-.3-.1-.7-.1-1V6.9H14.9c-1.9 0-3.3 1.4-3.3 3.3v29.7c0 1.7 1.2 3.1 2.6 3.3 0 0 .1 0 .1.1h22.3c.6-.2 1.2-.4 1.7-.9.7-.6 1-1.4 1-2.3V17.6c-.2-.1-.3-.1-.4-.1zM19 21.8h12.6c.6 0 1 .2 1.2.7.3.7-.2 1.4-1.1 1.4H19.2c-.6 0-1-.2-1.1-.7-.3-.6.2-1.3.9-1.4zm8.5 14.9h-8.3c-.6 0-.9-.3-1.1-.8-.1-.4 0-.9.4-1.1.2-.1.4-.2.7-.2h8.4c.6 0 1.1.5 1.1 1.1-.1.5-.5 1-1.2 1zm4.2-4.3H19.2c-.5 0-.9-.2-1.1-.6-.3-.7.1-1.4.9-1.5h12.6c.6 0 1 .2 1.2.7.3.7-.2 1.4-1.1 1.4zm.2-4.2H19.3c-.5 0-.9-.2-1.1-.7-.3-.7.2-1.5 1.1-1.5h12.5c.5 0 .9.2 1.1.7.2.7-.3 1.4-1 1.5z"
        />
        <path
          d="m19.3 16.1.9.9 3.1-3.1c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-3.5 3.5c-.5.5-1.4.5-1.9 0l-1.4-1.4c-.4-.4-.4-1 0-1.4.4-.2 1-.2 1.4.1z"
          style={{
            fill: "#f7a630",
          }}
        />
      </svg>
    )

  // Step 3 - collect car icon

  if (step === 3)
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 50 50"
        style={{
          enableBackground: "new 0 0 50 50",
          maxWidth: "200px",
        }}
        xmlSpace="preserve"
        {...rest}
      >
        <style>{".st1{fill:#f7a630}"}</style>
        <path
          d="M6.2 21.3c.1-.3.1-.6.2-.9.1-.4.3-.6.7-.6h3.4c.4 0 .6.2.7.7 0 .2.1.3.2.6.4-1.2.7-2.3 1.1-3.4.4-1.2.8-2.5 1.2-3.7.4-1.4 1.4-2.2 2.7-2.2h17.3c1.3 0 2.2.8 2.6 2.1.7 2.3 1.4 4.5 2.1 6.8 0 .1.1.2.1.4 0-.1.1-.1.1-.1.3-1.1.3-1.1 1.4-1.1h2.7c.6 0 .8.2.9.8.2.9.2 1.7 0 2.6-.2.5-.4.7-.8.7h-3.3l.2.2c.7.4 1.1 1.1 1.1 2v13.1c0 .8-.4 1.2-1.1 1.2h-3.9c-.7 0-1.1-.4-1.1-1.2v-4.5H15.3v4.4c0 .9-.3 1.3-1.1 1.3h-3.7c-.8 0-1.1-.4-1.1-1.3V26.4c0-1 .3-1.8 1.2-2.3h.1s0-.1-.1-.1H7.4c-.6 0-.8-.2-1-.8-.1-.2-.1-.5-.2-.7v-1.2zm29.9 2.6c0-.2-.1-.3-.1-.4l-2.4-7.8c-.1-.3-.2-.4-.5-.4H16.9c-.3 0-.4.1-.5.4L14 23.5c0 .1-.1.2-.1.4h22.2z"
          style={{
            fill: "#222",
          }}
          id="_x32_LgUAF_00000146463904720721845300000008125464969226062490_"
        />
        <path
          className="st1"
          d="M15.3 26.6h-1.7c-.5 0-1 .2-1.3.7-.6 1 0 2.4 1 2.4 1.3.1 2.6 0 3.9 0 .1 0 .2 0 .3-.1.6-.3 1-1 .9-1.7-.1-.8-.7-1.4-1.4-1.4-.6.1-1.2.1-1.7.1zM34.8 26.6h-1.7c-.9 0-1.5.8-1.5 1.7.1.9.6 1.5 1.5 1.5h3.3c.2 0 .4 0 .6-.1.6-.2 1-1 .9-1.7-.1-.8-.6-1.4-1.4-1.4h-1.7z"
        />
      </svg>
    )

  // Step 4 - transfer icon

  if (step === 4)
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 50 50"
        style={{
          enableBackground: "new 0 0 50 50",
        }}
        xmlSpace="preserve"
        {...rest}
      >
        <style>{".st0{fill:#222}"}</style>
        <path
          d="M25.9 20.6V16c0-.7-.6-1.2-1.3-1.2-.7 0-1.2.5-1.2 1.3v9.8c0 .6.2 1 .6 1.3 1.2 1 2.4 1.9 3.6 2.9.8.7 1.6 1.3 2.4 2 .5.4 1 .4 1.5.1.4-.3.7-.8.6-1.3-.1-.4-.4-.6-.7-.9-1.8-1.4-3.5-2.8-5.3-4.2-.2-.2-.3-.3-.3-.6.1-1.6.1-3.1.1-4.6z"
          style={{
            fill: "#f7a630",
          }}
          id="M6mS5u_00000094604405058435682590000002020824045799313029_"
        />
        <g id="AWzYr3_00000002378565619378652350000007583069362468504454_">
          <path
            className="st0"
            d="M24.5 44.4c-.7-.1-1.4-.2-2-.3-3.3-.6-6.2-1.9-8.8-4-.4-.3-.7-.6-1.1-.9-.3.3-.6.7-1 1-.3.4-.7.5-1.2.4-.5-.2-.7-.5-.8-1-.3-1.4-.6-2.9-.9-4.3-.2-1.2-.5-2.4-.7-3.6-.2-.9.7-1.6 1.5-1.3 2.3.8 4.7 1.5 7 2.3 1.1.4 1.4 1.3.5 2.1-.4.4-.8.7-1.1 1.1 1 1 2.2 1.7 3.4 2.4 1.6.8 3.3 1.3 5.1 1.5 2.6.3 5.1-.1 7.5-1.2 3-1.4 5.4-3.5 7-6.4.3-.5.5-.9.7-1.4.4-.8.9-2.2 1.1-3.9.1-.4.1-.8.1-1.2.1-1.3 1.2-2.3 2.5-2.2 1.2.1 2.1 1.1 2.1 2.3v.5c0 .1-.1.2-.1.3-.2 1-.3 2.1-.5 3.1-1 4.1-3.2 7.5-6.5 10.2-2.8 2.3-5.9 3.7-9.5 4.3-.5.1-1.1.1-1.6.2h-2.7zM35.9 14.1c-2.1-1.8-4.3-3-6.9-3.6-3.2-.7-6.3-.4-9.3 1-3 1.4-5.3 3.5-6.9 6.4-.6 1.1-1.6 3.2-1.8 6v.6c0 1.3-1.1 2.2-2.4 2.2h-.1c-1.2-.1-2.2-1.1-2.2-2.4 0-.9.1-1.8.2-2.4.4-2.6 1.3-4.6 1.8-5.6.6-1.3 1.4-2.4 2.3-3.5 3.1-3.7 6.9-6 11.6-6.8 5.2-.9 10 .1 14.3 3 .7.4 1.3 1 1.9 1.5.1.1.2.2.5.4.3-.4.6-.7.9-1 .3-.3.7-.6 1.2-.4.5.2.8.6.9 1.1.4 2.2.9 4.4 1.3 6.6.1.5.2.9.3 1.4.1.9-.7 1.5-1.5 1.3-2.4-.8-4.7-1.5-7.1-2.3-1.1-.4-1.3-1.3-.5-2.1.7-.7 1.1-1 1.5-1.4z"
          />
        </g>
      </svg>
    )

  return null
}

export default StepsIcon
