import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import vars from "styles/variables"
import Link from "components/common/Link"
import NeedHelp from "components/bye-buy-motors/NeedHelp"
import Social from "components/core/Social"

function Footer() {
  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col xs="12">
            <p className="font-weight-bold">
              <a href="/">
                <img
                  src="/images/logo-white-4c.png"
                  alt="Bye Buy Motors footer logo"
                />
              </a>
            </p>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="12" sm="6">
            <NeedHelp topMargin={false} />
          </Col>
          <Col xs="12" sm="auto">
            <div className="mt-2">
              <Social size={30} />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between mt-2">
          <Col xs="12" sm="6">
            <p className="mb-3">
              <StyledSmallPrint>
                &copy;{new Date().getFullYear()} ByeBuyMotors
              </StyledSmallPrint>
            </p>
          </Col>
          <Col xs="12" sm="auto">
            <Link to="https://www.inspiredagency.co.uk/" target="_blank">
              <StyledSmallPrint>
                Another <u>Inspired Agency</u> Website
              </StyledSmallPrint>
            </Link>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  margin-top: 0rem;
  padding: 1.8rem 0;
  background: ${vars.black};
  color: ${vars.white};

  a {
    color: ${vars.white};
  }
`
const StyledSmallPrint = styled.span`
  font-size: 0.7rem;
`
