import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { default as MdcCheckbox } from "@material-ui/core/Checkbox"
import vars from "styles/variables"

function CheckboxWithLabel({
  name,
  label,
  checked,
  defaultChecked,
  onChange,
  style,
}) {
  if (!style?.color) style = { color: vars.primary, ...style }

  return (
    <FormControlLabel
      control={
        <MdcCheckbox
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={onChange}
          name={name}
          style={style}
        />
      }
      label={label}
      labelPlacement="start"
      className="ml-0"
    />
  )
}

export default CheckboxWithLabel
