import React from "react"
import { Col, Row } from "reactstrap"

function ForbiddenPage() {
  return (
    <Row>
      <Col className="text-danger">
        <h1>403: Forbidden</h1>
        <p>You do not have permission to access this page.</p>
      </Col>
    </Row>
  )
}

export default ForbiddenPage
