//Locked
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"
import useFetch from "hooks/useFetch"
import PageTemplate from "templates/PageTemplate"
import {
  getValuationImages,
  uploadValuationImage,
} from "services/valuationsService"
import useApiError from "hooks/useApiError"
import ErrorMessage from "components/core/ErrorMessage"
import styled from "styled-components"
import ProgressBar from "./components/ProgressBar"
import NextBackButtons from "./components/NextBackButtons"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import LoadingIcon from "components/svg/LoadingIcon"
import RoundedForm from "./components/RoundedForm"
import vars from "styles/variables"

function ValuationImagesPage() {
  const [isSaving, setIsSaving] = useState(false)
  const [images, setImages] = useState([])
  const { data, isLoading, error } = useFetch(() => getValuationImages())
  const { responseError, showError } = useApiError()

  useEffect(() => {
    if (data && data.vehicleImages) setImages(data.vehicleImages)
  }, [data])

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  const handleUploadAttachment = (e, id) => {
    const files = Array.from(e.target.files)
    setIsSaving(true)
    changeImage(id, true)

    const formData = new FormData()
    const imageToChange = images.find(x => x.id === id)

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i])
      formData.append("fileNames", files[i].name)
      formData.append("imageCaptions", imageToChange.imageCaption)
    }
    formData.append("id", id)
    uploadValuationImage(formData)
      .then(data => {
        if (!responseError(data)) {
          setIsSaving(false)
          changeImage(data.id, false, false, data.images[0].thumbnailLocation)
        }
      })
      .catch(err => {
        console.log("catch", err)
        showError(err.message)
      })
    e.target.value = null
  }

  const changeImage = (imageId, isSaving, isDefault, thumbnailLocation) => {
    const newImages = [...images]
    const thisImage = newImages.find(x => x.id === imageId)
    thisImage.isSaving = isSaving
    if (thumbnailLocation) thisImage.thumbnailLocation = thumbnailLocation
    if (isDefault !== undefined) thisImage.isDefault = isDefault
    setImages(newImages)
  }

  //EVENTS END

  const imageList = (start, end) => {
    return data.vehicleImages.map((image, i) => {
      if (i >= start && i <= end)
        return (
          <Col xs="6" sm="4" lg="3" key={image.id}>
            <StyledImageContainer
              onClick={() =>
                document.getElementById("file-" + image.id).click()
              }
            >
              <StyledInput
                id={"file-" + image.id}
                name="file"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={e => handleUploadAttachment(e, image.id)}
              />
              <StyledThumbnail
                isDefault={image.isDefault}
                src={image.thumbnailLocation || image.imageLocation}
              />
              {image.isSaving ? (
                <StyledSpinner>
                  <LoadingIcon />
                </StyledSpinner>
              ) : (
                <StyledCaption isDefault={image.isDefault}>
                  {image.imageCaption}
                </StyledCaption>
              )}
            </StyledImageContainer>
          </Col>
        )
    })
  }

  return (
    <PageTemplate
      title="Valuation Images"
      content="Content"
      hideBorders
      hideFooter
    >
      <RoundedForm>
        <ProgressBar currentStep={3} totalSteps={4}>
          Add Images
        </ProgressBar>
        <Row>
          <StyledHeadingCol xs="12">
            These images are required:
          </StyledHeadingCol>
          {isLoading && <ValuationImagesLoadingSkeleton />}
          {!isLoading && (
            <>
              {imageList(0, 3)}
              <StyledHeadingCol xs="12">
                Optional:
                <div style={{ fontSize: "12px", color: vars.white }}>
                  Please provide as many images as possible to help us provide
                  the most accurate valuation for your car.’
                </div>
              </StyledHeadingCol>
              {imageList(4, 100)}
              <NextBackButtons
                isLoading={isSaving}
                to="/valuation/you"
                //  Buttons are disabled if the first 4 images haven't been populated
                disabledMessage={
                  data?.vehicleImages.slice(0, 4).some(i => i.isDefault) &&
                  "Please upload the mandatory images"
                }
              />
            </>
          )}
        </Row>
      </RoundedForm>
    </PageTemplate>
  )
}

export default ValuationImagesPage

function ValuationImagesLoadingSkeleton() {
  return (
    <SkeletonTheme baseColor={vars.grey_600} highlightColor={vars.grey_700}>
      {Array(20)
        .fill(1)
        .map((o, i) => {
          return (
            <Col xs="3" className="mb-4" key={i}>
              <Skeleton height={110} />
            </Col>
          )
        })}
    </SkeletonTheme>
  )
}

const StyledHeadingCol = styled(Col)`
  color: ${vars.primary};
  margin-bottom: 1em;
`

const StyledInput = styled.input`
  //Essentially we want to hide the standard input
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const StyledImageContainer = styled.div`
  position: relative;
  text-align: center;
  cursor: pointer;
`

const StyledThumbnail = styled.img`
  position: relative;
  max-width: 100%;
  top: 0;
  ${props => (props.isDefault ? "opacity: 0.3" : "")};
  padding-bottom: 20px;
`

const StyledCaption = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 1 !important;
  color: ${props => (props.isDefault ? vars.grey_200 : vars.white)} !important;
  ${props =>
    props.isDefault
      ? ""
      : "text-shadow: 2px 2px gray;"};import RoundedForm from './components/RoundedForm';
import RoundedForm from './components/RoundedForm';
import RoundedForm from './components/RoundedForm';

`

const StyledSpinner = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${vars.yellow};
`
