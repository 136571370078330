import React, { useState } from "react"
import styled from "styled-components"
import vars from "styles/variables"
import ArrowDownIcon from "components/svg/ArrowDownIcon"

export default function Accordion({ data }) {
  const [activeItem, setActiveItem] = useState()
  if (!data) return null

  function handleActiveItem(id) {
    setActiveItem(id !== activeItem && id)
  }

  return (
    <>
      {data.map(item => {
        return (
          <AccordionItem
            key={item.id}
            title={item.title || item.name || "Unknown"}
            active={item.id === activeItem}
            content={item.content}
            onClick={() => handleActiveItem(item.id)}
          />
        )
      })}
    </>
  )
}

function AccordionItem({ title, active, content, onClick }) {
  return (
    <StyledAccordionItem>
      <StyledAccordionTitle onClick={onClick}>
        {title}
        <StyledArrowIcon rotate={active} />
      </StyledAccordionTitle>
      {active && (
        <StyledAccordionContent
          className="animate-fade-in "
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </StyledAccordionItem>
  )
}

const StyledAccordionItem = styled.div`
  border-bottom: 1px solid ${vars.grey_500};
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`

const StyledAccordionTitle = styled.h3`
  display: flex;
  cursor: pointer;
  margin-bottom: 0;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  position: relative;
  padding-right: 34px;

  @media (max-width: ${vars.screen_xs_max}) {
    line-height: 1.3;
  }

  svg {
    position: absolute;
    transition: ease-in-out 500ms;
    top: -3px;
    right: 0;
  }
`

const StyledAccordionContent = styled.div`
  margin-top: 1rem;

  *:last-child {
    margin-bottom: 0;
  }
`

const StyledArrowIcon = styled(({ rotate, ...rest }) => (
  <ArrowDownIcon {...rest} />
))`
  transform: rotate(${props => (props.rotate ? "180deg" : "0")});
`
