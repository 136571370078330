import React, { useContext, createContext, useRef } from "react"
import { useArticles } from "hooks/useArticles"

const Context = createContext()

export function RefreshProvider({ children }) {
  const value = useProvider()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default function useRefresh() {
  return useContext(Context)
}

function useProvider() {
  const isInitialLoad = useRef(true)
  const { refreshArticles } = useArticles()

  // The above hooks contain providers which load their data on mount.
  // The below function prevents us calling the api to get the same data
  // when initially loading the app for the first time.
  function handleInitialLoad() {
    if (isInitialLoad.current) {
      isInitialLoad.current = false
      return true
    }
  }

  function refreshAll() {
    if (handleInitialLoad()) return

    Promise.all([
      refreshArticles()
    ])
  }

  return {
    refreshAll,
  }
}
