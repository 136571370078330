//Locked
import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import useFetch from "hooks/useFetch"
import { useForm } from "react-hook-form"
import { addValuationNote } from "services/valuationsService"
import ErrorMessage from "components/core/ErrorMessage"
import Button from "components/common/Button"
import styled from "styled-components"
import TextField from "components/common/TextField"
import vars from "styles/variables"

function ValuationNotes({ guid, telephone, mainNoteType, get, forceReload }) {
  const { data, setData, isLoading, error } = useFetch(() => get(guid))
  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  })

  const [newNoteType, setNewNoteType] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    return () => {
      reload()
    }
  }, [forceReload])

  async function reload() {
    var newData = await get(guid)
    if (newData) setData(newData)
  }

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  async function onSubmit(formData) {
    setIsSaving(true)
    const response = await addValuationNote({
      ...formData,
      guid: guid,
      noteType: newNoteType,
    })

    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response) //Move to hook
    }

    let newData = { ...data }
    newData.valuationNotes.push(response)
    setData(newData)

    setIsSaving(false)
    setNewNoteType(null)
  }
  function handleError(error) {
    //responseError(error)
    //if (!isMounted.current) return
    setIsSaving(false)
  }

  //EVENTS END

  if (isLoading) return null

  return (
    <>
      {mainNoteType === vars.noteType_internal && (
        <Row className="mt-2">
          <Col xs="12" sm="6">
            <Button
              isFullWidth
              onClick={() => setNewNoteType(vars.noteType_toCustomer)}
            >
              Email Customer
            </Button>
          </Col>
          <Col xs="12" sm="6">
            <Button isFullWidth to={"tel:" + telephone}>
              Call Customer
            </Button>
          </Col>
        </Row>
      )}

      <Row>
        <Col className="mt-4 mb-2">
          <hr />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <StyledSectionHeader>History</StyledSectionHeader>
        </Col>
        <Col xs="auto">
          <Button
            styleType="black"
            outline
            onClick={() => setNewNoteType(mainNoteType)}
            isLoading={isSaving}
          >
            {mainNoteType === vars.noteType_internal
              ? "+ Add Note"
              : "Send Us a Message"}
          </Button>
        </Col>
      </Row>
      {newNoteType > 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs="12">
              <TextField
                key="noteContent"
                name="noteContent"
                autoFocus
                multiline
                required
                control={control}
                label={
                  newNoteType === vars.noteType_internal
                    ? "Internal Note"
                    : newNoteType === vars.noteType_toCustomer
                    ? "Send Mail to Customer"
                    : "Send Message"
                }
                error={formState.errors.noteContent}
              />
            </Col>
            <Col xs="auto">
              <Button isLoading={isSaving} type="submit" styleType="primary">
                {newNoteType === vars.noteType_internal ? "Save" : "Send"}
              </Button>
            </Col>
          </Row>
        </form>
      )}

      {data.valuationNotes.map((valuationNote, i) => {
        return (
          <Row
            key={i}
            className="mt-1"
            style={{
              color:
                valuationNote.noteType == vars.noteType_fromCustomer
                  ? vars.yellow
                  : valuationNote.noteType == vars.noteType_toCustomer
                  ? vars.white
                  : vars.orange,
            }}
          >
            <StyledNoteHeaderCol>
              {valuationNote.createdOn}
              {valuationNote.noteType == vars.noteType_internal
                ? " (internal)"
                : ""}
            </StyledNoteHeaderCol>
            <StyledNoteHeaderCol xs="auto">
              {valuationNote.createdBy}
            </StyledNoteHeaderCol>
            <Col xs="12" className="mt-2">
              <div
                dangerouslySetInnerHTML={{ __html: valuationNote.content }}
              />
            </Col>
          </Row>
        )
      })}

      <Row>
        <Col className="mt-3 mb-2">
          <hr />
        </Col>
      </Row>
    </>
  )
}

export default ValuationNotes

const StyledSectionHeader = styled.div`
  color: ${vars.orange};
  font-weight: bold;
`

const StyledNoteHeaderCol = styled(Col)`
  font-weight: bold;
  font-size: 0.8rem;
`
