//Locked
import React from "react"
import useFetch from "hooks/useFetch"
import { getAutotraderValuation } from "services/valuationsService"
import styled from "styled-components"

import vars from "styles/variables"
import SummaryBar from "./SummaryBar"

function AutotraderValuation({ guid }) {
  const { data, setData, isLoading, error } = useFetch(() =>
    getAutotraderValuation(guid)
  )

  if (isLoading) return null
  console.log("ERROR", error)
  if (error || !data.vehicle)
    return (
      <StyledSectionHeader>No Autotrader data available</StyledSectionHeader>
    )

  console.log(data)

  return (
    <>
      <StyledSectionHeader>Autotrader</StyledSectionHeader>
      <SummaryBar
        alt={1}
        label="Registration"
        value={data.vehicle.registration}
      />
      <SummaryBar alt={0} label="Make" value={data.vehicle.make} />
      <SummaryBar alt={1} label="Model" value={data.vehicle.model} />
      <SummaryBar alt={0} label="Colour" value={data.vehicle.colour} />

      <SummaryBar alt={1} label="FuelType" value={data.vehicle.fuelType} />
      <SummaryBar
        alt={0}
        label="TransmissionType"
        value={data.vehicle.transmissionType}
      />
      <SummaryBar alt={1} label="Doors" value={data.vehicle.doors} />
      <SummaryBar
        alt={0}
        label="Derivative"
        fullWidth
        value={data.vehicle.derivative}
      />

      {data.valuations && (
        <>
          <StyledSectionHeader>Valuations</StyledSectionHeader>
          {data.valuations.partExchange && (
            <SummaryBar
              alt={1}
              label="Part Ex"
              value={data.valuations.partExchange.amountString}
            />
          )}
          {data.valuations.trade && (
            <SummaryBar
              alt={0}
              label="Trade"
              value={data.valuations.trade.amountString}
            />
          )}
          {data.valuations.retail && (
            <SummaryBar
              alt={1}
              label="Retail"
              value={data.valuations.retail.amountString}
            />
          )}
        </>
      )}
    </>
  )
}

export default AutotraderValuation

const StyledSectionHeader = styled.div`
  padding: 10px 0;
  color: ${vars.orange};
  font-weight: bold;
  margin-top: 1rem;
`
