import React from "react"

function ErrorMessage({ content }) {
  return (
    <div className="text-danger">
      {content || "Something has gone wrong, please try again later."}
    </div>
  )
}

export default ErrorMessage
