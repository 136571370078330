import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"

/*

White header followed by a black container with a rounded top-left corner
Thanks, Designers!

*/

function RoundedForm({ fullWidth = false, children }) {
  let outer_xs,
    outer_sm,
    outer_md,
    outer_lg,
    inner_xs,
    inner_sm,
    inner_md,
    inner_lg

  if (fullWidth) {
    //Columns sizes for form pages
    outer_xs = "hidden"
    outer_sm = 1
    outer_md = 1
    outer_lg = 2
    inner_xs = 12
  } else {
    outer_xs = "hidden"
    outer_sm = 2
    outer_md = 3
    outer_lg = 4
    inner_xs = 12
  }
  inner_sm = 12 - outer_sm * 2
  inner_md = 12 - outer_md * 2
  inner_lg = 12 - outer_lg * 2

  return (
    <Container fluid style={{ backgroundColor: vars.white }}>
      <Row>
        <StyledHeaderCol xs="12" className="d-flex justify-content-center">
          <a href="/">
            <img src="/images/logo-black-4c.png" alt="Bye Buy Motors logo" />
          </a>
        </StyledHeaderCol>
      </Row>
      <Row>
        <Col xs="12" className="p-0">
          <Container fluid>
            <Row>
              <StyledRoundedCol
                xs={outer_xs}
                sm={outer_sm}
                md={outer_md}
                lg={outer_lg}
              ></StyledRoundedCol>
              <StyledBlackCol
                xs={inner_xs}
                sm={inner_sm}
                md={inner_md}
                lg={inner_lg}
              >
                {children}
              </StyledBlackCol>
              <StyledBlackCol
                xs={outer_xs}
                sm={outer_sm}
                md={outer_md}
                lg={outer_lg}
              ></StyledBlackCol>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default RoundedForm

const StyledHeaderCol = styled(Col)`
  padding: 1em 0;
`

const StyledBlackCol = styled(Col)`
  background-color: ${vars.black};
`

const StyledRoundedCol = styled(Col)`
  border-top-left-radius: 50px;
  background-color: ${vars.black};
  border: 2px solid ${vars.black};
`
