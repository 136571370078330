import React from "react"
import { PropTypes } from "prop-types"

function LoadingIcon({ colour = "white", width = 34, isFullScreen, ...rest }) {
  const imgPath = getImagePath(colour)

  if (isFullScreen)
    return (
      <div className="vh-100 d-flex justify-content-center">
        <img alt="Loading..." src={imgPath} width={width} {...rest} />
      </div>
    )

  return <img alt="Loading..." src={imgPath} width={width} {...rest} />
}

export default LoadingIcon

function getImagePath(colour) {
  switch (colour) {
    case "primary":
      return require("assets/images/loading-primary.svg").default
    default:
          return require("assets/images/loading-white.svg").default
  }
}

LoadingIcon.propTypes = {
  colour: PropTypes.oneOf(["white", "primary"]),
}
