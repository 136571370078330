import React from "react"
import styled from "styled-components"
import vars from "styles/variables"
import TickIcon from "components/svg/TickIcon"

function ProgressBar({ currentStep, totalSteps, children }) {
  //Build an array of progress types to make the render easier
  //There are types: done, doing or todo
  const eachPercent = Math.floor(100 / totalSteps) - 2
  const bars = []
  for (let i = 1; i < currentStep; i++) {
    bars.push("done")
  }
  bars.push("doing")
  for (let i = currentStep; i < totalSteps; i++) {
    bars.push("todo")
  }

  return (
    <>
      <div className="d-flex justify-content-between mt-3 pt-3">
        {bars.map((bar, i) => {
          if (bar === "done") {
            return (
              <StyledProgressBar
                key={i}
                percent={eachPercent}
                color={vars.orange}
              >
                <StyledTick>
                  <StyledTickIcon />
                </StyledTick>
              </StyledProgressBar>
            )
          } else if (bar === "doing") {
            return (
              <StyledInProgressContainer key={i} percent={eachPercent}>
                <StyledInProgressLeft />
                <StyledInProgressRight />
              </StyledInProgressContainer>
            )
          } else {
            return (
              <StyledProgressBar
                key={i}
                percent={eachPercent}
                color={vars.lighterBackground}
              />
            )
          }
        })}
      </div>

      <StyledCaption>{children}</StyledCaption>
    </>
  )
}

export default ProgressBar

const StyledTick = styled.div`
  position: relative;
  top: -6px;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: ${vars.orange};
  border-radius: 8px;
`

const StyledTickIcon = styled(TickIcon)`
  margin-bottom: 4px;
`

const StyledProgressBar = styled.div`
  background-color: ${props => props.color};
  height: 5px;
  width: ${props => props.percent}%;
  float: left;
  text-align: center;
  padding-right: 12px;
  border-radius: 2px;
  position: relative;
`

const StyledInProgressContainer = styled.div`
  width: ${props => props.percent}%;
`

const StyledInProgressLeft = styled.div`
  background-color: ${vars.orange};
  height: 5px;
  width: 50%;
  float: left;
  text-align: center;
  padding-right: 12px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`

const StyledInProgressRight = styled.div`
  background-color: ${vars.lighterBackground};
  height: 5px;
  width: 50%;
  float: left;
  text-align: center;
  padding-right: 12px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`

const StyledCaption = styled.div`
  margin: 30px 0;
  font-size: 1.7rem;
  font-weight: bold;
`
