import * as React from "react"

const TwitterIcon = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.11 9.328c-.317.14-.657.236-1.014.278.364-.218.645-.564.776-.977-.341.203-.719.35-1.121.429a1.767 1.767 0 0 0-3.01 1.611A5.015 5.015 0 0 1 8.1 8.823a1.766 1.766 0 0 0 .547 2.358 1.76 1.76 0 0 1-.801-.22v.022c0 .856.609 1.57 1.417 1.732a1.772 1.772 0 0 1-.798.03 1.768 1.768 0 0 0 1.65 1.227 3.544 3.544 0 0 1-2.615.732c.782.5 1.71.793 2.708.793 3.25 0 5.026-2.691 5.026-5.026 0-.076-.002-.152-.005-.228a3.59 3.59 0 0 0 .881-.915Z"
      fill="#fff"
    />
    <circle cx={12} cy={12} r={11.5} stroke="#fff" />
  </svg>
)

export default TwitterIcon
