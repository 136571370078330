import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"
import useFetch from "hooks/useFetch"
import { useForm } from "react-hook-form"
import { useParams } from "react-router"
import PageTemplate from "templates/PageTemplate"
import {
  getValuationAboutYou,
  saveValuationAboutYou,
} from "services/valuationsService"
import ErrorMessage from "components/core/ErrorMessage"
import TextField from "components/common/TextField"
import Radios from "components/common/Radios"
import ProgressBar from "./components/ProgressBar"
import NextBackButtons from "./components/NextBackButtons"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import RoundedForm from "./components/RoundedForm"
import vars from "styles/variables"

function ValuationAboutYouPage() {
  const history = useHistory()
  const { slug } = useParams()
  const [isSaving, setIsSaving] = useState(false)
  const { data, isLoading, error } = useFetch(() => getValuationAboutYou(slug))
  const { control, handleSubmit, formState, register } = useForm({
    mode: "onChange",
  })

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  async function onSubmit(formData) {
    setIsSaving(true)
    const response = await saveValuationAboutYou({ ...data, ...formData })

    //if (!isMounted.current) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response) //Move to hook
    }
    setIsSaving(false)
    history.push("/valuation/offer/" + data.guid)
  }
  function handleError(error) {
    //responseError(error)
    //if (!isMounted.current) return
    setIsSaving(false)
  }
  //End of Submit Form

  //EVENTS END

  return (
    <PageTemplate title="Valuation" content="Content" hideBorders hideFooter>
      <RoundedForm>
        <ProgressBar currentStep={4} totalSteps={4}>
          About You
        </ProgressBar>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {isLoading && <ValuationAboutYouLoadingSkeleton />}
            {!isLoading && (
              <>
                <Col xs="12">
                  <TextField
                    autoFocus
                    required
                    control={control}
                    label="Full Name*"
                    name="fullName" //Match
                    error={formState.errors.fullName}
                    defaultValue={data.fullName || ""}
                  />
                </Col>

                <Col xs="12">
                  <TextField
                    required
                    control={control}
                    label="Email*"
                    name="email" //Match
                    type="email"
                    error={formState.errors.email}
                    defaultValue={data.email || ""}
                  />
                </Col>

                <Col xs="12">
                  <TextField
                    required
                    control={control}
                    label="Telephone*"
                    name="telephone" //Match
                    type="tel"
                    error={formState.errors.telephone}
                    defaultValue={data.telephone || ""}
                  />
                </Col>

                <Col xs="12">
                  <TextField
                    required
                    control={control}
                    label="Post Code*"
                    placeHolder="Enter Post Code"
                    name="postCode" //Match
                    error={formState.errors.postCode}
                    defaultValue={data.postCode || ""}
                  />
                </Col>

                <Col xs="12">
                  <Radios
                    control={control}
                    register={register}
                    label="Preferred Contact Method" //match
                    name="preferredContactMethod"
                    error={formState.errors.preferredContactMethod}
                    defaultValue={data.preferredContactMethod || ""}
                    options={data.preferredContactMethodItems}
                  />
                </Col>

                <Col xs="12">
                  <Radios
                    control={control}
                    register={register}
                    label="Preferred Contact Time" //match
                    name="preferredContactTime"
                    error={formState.errors.preferredContactTime}
                    defaultValue={data.preferredContactTime || ""}
                    options={data.preferredContactTimeItems}
                  />
                </Col>

                <NextBackButtons isLoading={isSaving} styleType="green">
                  Value My Car
                </NextBackButtons>
              </>
            )}
          </Row>
        </form>
      </RoundedForm>
    </PageTemplate>
  )
}

export default ValuationAboutYouPage

function ValuationAboutYouLoadingSkeleton() {
  return (
    <SkeletonTheme baseColor={vars.grey_600} highlightColor={vars.grey_700}>
      <Col xs="12" style={{ height: "15px" }}></Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-4">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mt-1 mb-5">
        <Skeleton height={150} />
      </Col>
    </SkeletonTheme>
  )
}
