//Locked
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"
import useFetch from "hooks/useFetch"
import { useForm } from "react-hook-form"
import PageTemplate from "templates/PageTemplate"
import {
  getValuationCarExtra,
  saveValuationCarExtra,
} from "services/valuationsService"
import ErrorMessage from "components/core/ErrorMessage"
import TextField from "components/common/TextField"
import Select from "components/common/Select"
import ProgressBar from "./components/ProgressBar"
import NextBackButtons from "./components/NextBackButtons"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import RoundedForm from "./components/RoundedForm"
import vars from "styles/variables"

function ValuationCarExtraPage() {
  const history = useHistory()
  const [isSaving, setIsSaving] = useState(false)
  const { data, isLoading, error } = useFetch(() => getValuationCarExtra())
  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  })

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  async function onSubmit(formData) {
    console.log("data", data, formData)

    setIsSaving(true)

    const response = await saveValuationCarExtra({ ...data, ...formData })

    //if (!isMounted.current) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response) //Move to hook
    }
    setIsSaving(false)
    history.push("/valuation/images")
  }
  function handleError(error) {
    //responseError(error)
    //if (!isMounted.current) return
    setIsSaving(false)
  }
  //End of Submit Form

  //EVENTS END

  return (
    <PageTemplate title="Valuation" content="Content" hideBorders hideFooter>
      <RoundedForm>
        <ProgressBar currentStep={2} totalSteps={4}>
          Additional Vehicle Information
        </ProgressBar>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {isLoading && <ValuationCarExtraLoadingSkeleton />}
            {!isLoading && (
              <>
                <Col xs="12">
                  <TextField
                    autoFocus
                    multiline
                    style={{ height: "4.5rem" }}
                    required
                    control={control}
                    label="Spec*"
                    name="spec"
                    placeholder="Bluetooth, Sat Nav, etc."
                    error={formState.errors.spec}
                    defaultValue={data.spec || ""}
                  />
                </Col>

                <Col xs="12">
                  <Select
                    control={control}
                    required
                    label="Transmission*" //match
                    name="transmission"
                    error={formState.errors.transmission}
                    defaultValue={data.transmission || ""}
                    options={data.transmissionItems}
                  />
                </Col>

                <Col xs="12">
                  <TextField
                    control={control}
                    required
                    multiline
                    style={{ height: "4.5rem" }}
                    label="Additional*"
                    sublabel="Tell us everything!"
                    name="additional"
                    placeholder="Aftermarket spoiler. Stored in garage overnight."
                    error={formState.errors.additional}
                    defaultValue={data.additional || ""}
                  />
                </Col>

                <Col xs="12">
                  <TextField
                    control={control}
                    required
                    multiline
                    style={{ height: "4.5rem" }}
                    label="Mechanical Faults*"
                    name="mechanicalFaults"
                    placeholder="None"
                    error={formState.errors.mechanicalFaults}
                    defaultValue={data.mechanicalFaults || ""}
                  />
                </Col>

                <Col xs="12">
                  <TextField
                    control={control}
                    required
                    multiline
                    style={{ height: "4.5rem" }}
                    label="Bodywork Faults*"
                    name="bodyworkFaults"
                    placeholder="Scratch on door"
                    error={formState.errors.bodyworkFaults}
                    defaultValue={data.bodyworkFaults || ""}
                  />
                </Col>

                <NextBackButtons isLoading={isSaving} />
              </>
            )}
          </Row>
        </form>
      </RoundedForm>
    </PageTemplate>
  )
}

export default ValuationCarExtraPage

function ValuationCarExtraLoadingSkeleton() {
  return (
    <SkeletonTheme baseColor={vars.grey_600} highlightColor={vars.grey_700}>
      <Col xs="12" style={{ height: "15px" }}></Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5 mt-3">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
      <Col xs="12" className="mb-5">
        <Skeleton height={50} />
      </Col>
    </SkeletonTheme>
  )
}
