// Colours
const black = "#222222"
const white = "#ffffff"
const red = "#f5425a"
const darkRed = "#762e2a"
const blue = "#00A8D8"
const darkBlue = "#0068a5"
const orange = "#FFAA00"
const darkOrange = "#c06615"
const yellow = "#fff33e"
const darkYellow = "#d7c700"
const green = "#47B36E"
const darkGreen = "#2b7c39"

const grey_100 = "#f2f2f2"
const grey_200 = "#F8F8F8"
const grey_300 = "#ECECEC"
const grey_400 = "#a6a6a6"
const grey_500 = "#979797"
const grey_600 = "#343434"
const grey_700 = "#222222"

const primary = orange
const lighterBackground = grey_600
const primaryDark = darkBlue
const secondary = blue

// Gradients
const blueGradient = "linear-gradient(to bottom, #00A8D8 0%, #0068a5 100%)"
const orangeGradient = "linear-gradient(to bottom, #FFAA00 0%, #EE9900 100%)"

// Breakpoints
const screen_xs_max = "575px"
const screen_sm_min = "576px"
const screen_sm_max = "767px"
const screen_md_min = "768px"
const screen_md_max = "991px"
const screen_lg_min = "992px"
const screen_lg_max = "1199px"
const screen_xl_min = "1200px"

// Fonts
const primaryFont = `Poppins, 'Times New Roman', serif`
const secondaryFont = `sans-serif`
const primaryAdminFont = `'Roboto', sans-serif`

// Functions
const blackTransparent = (transparency = "0.5") =>
  `rgba(0, 0, 0, ${transparency})`

const whiteTransparent = (transparency = "0.5") =>
  `rgba(225, 225, 225, ${transparency})`

//Columns sizes for form pages
const outer_xs = "hidden"
const outer_sm = 2
const outer_md = 3
const outer_lg = 4
const inner_xs = 12
const inner_sm = 12 - outer_sm * 2
const inner_md = 12 - outer_md * 2
const inner_lg = 12 - outer_lg * 2

const noteType_internal = 1
const noteType_toCustomer = 2
const noteType_fromCustomer = 3

export default {
  black,
  white,
  blue,
  darkBlue,
  red,
  darkRed,
  orange,
  darkOrange,
  yellow,
  darkYellow,
  green,
  darkGreen,

  primary,
  primaryDark,
  secondary,
  lighterBackground,

  blueGradient,
  orangeGradient,

  grey_100,
  grey_200,
  grey_300,
  grey_400,
  grey_500,
  grey_600,
  grey_700,

  screen_xs_max,
  screen_sm_min,
  screen_sm_max,
  screen_md_min,
  screen_md_max,
  screen_lg_min,
  screen_lg_max,
  screen_xl_min,

  outer_xs,
  outer_sm,
  outer_md,
  outer_lg,
  inner_xs,
  inner_sm,
  inner_md,
  inner_lg,

  noteType_internal,
  noteType_toCustomer,
  noteType_fromCustomer,

  primaryFont,
  secondaryFont,
  primaryAdminFont,

  blackTransparent,
  whiteTransparent,
}
