import React from "react"

function ArrowDownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="rotate(90 12 12)">
          <path
            fill="#000"
            className="svg-fill"
            d="M10 6L8.59 7.41 13.17 12 8.59 16.59 10 18 16 12z"
          ></path>
          <path d="M0 0L24 0 24 24 0 24z"></path>
        </g>
      </g>
    </svg>
  )
}

export default ArrowDownIcon
