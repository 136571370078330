import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react"
import { getArticleListFiltered } from "services/articleService"
import { ArticleTypeEnum } from "utils/articleUtil"

const Context = createContext()

// Provider component that wraps your app and makes our object
// available to any child component
export function ArticleProvider({ children }) {
  const value = useProvider()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

// Hook for child components to get the object
// and re-render when it changes
export const useArticles = () => {
  return useContext(Context)
}

// Provider hook that creates object and handles state
function useProvider() {
  //! Removed offers and events as they are not used and now that we have added
  //! pagination for news this hook would need to be re-built.
  const isMounted = useRef(true)
  const [news, setNews] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    onMount()
    return () => {
      isMounted.current = false
    }
  }, [])

  async function onMount() {
    // const response = await getArticleListFiltered(ArticleTypeEnum.news)
    // if (!isMounted.current) return
    // if (!response.isSuccess) return handleError(response.responseMessage)
    // setNews(response)
    // setIsLoading(false)
  }

  function handleError(error) {
    if (!isMounted.current) return
    setError(error)
  }

  async function refreshArticles(showLoading) {
    showLoading && setIsLoading(true)
    onMount()
  }

  async function loadMoreNews() {
    setIsLoadingMore(true)

    const response = await getArticleListFiltered(
      ArticleTypeEnum.news,
      news.nextPage
    )

    if (!isMounted.current) return
    if (!response.isSuccess) return handleError(response.responseMessage)

    const mergeNews = {
      ...news,
      hasMoreItems: response.hasMoreItems,
      nextPage: response.nextPage,
      items: [...news.items, ...response.items],
    }

    setNews(mergeNews)
    setIsLoadingMore(false)
  }

  return {
    news,
    isLoading,
    isLoadingMore,
    error,
    refreshArticles,
    loadMoreNews,
  }
}
