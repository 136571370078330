import React from "react"
import BenefitsList from "./BenefitsList"
import styled from "styled-components"
import vars from "styles/variables"

function IntroBenefits() {
  return (
    <StyledBenefits>
      <div className="pb-3">
        <img src="/images/logo-black-4c.png" alt="Bye Buy Motors logo" />
      </div>
      <StyledHeading className="mt-4">
        Bye Buy Motors offers more than the rest
      </StyledHeading>
      <div>
        Because no two cars are the same, with Bye Buy Motors you can save
        £000’s, with a valuation that genuinely reflects your vehicle and in
        particular its options, specification and condition.
      </div>
      <BenefitsList />
    </StyledBenefits>
  )
}

export default IntroBenefits

const StyledBenefits = styled.div`
  padding: 30px;
  color: ${vars.black};
  background-color: ${vars.grey_300};
  height: 100%;
`

const StyledHeading = styled.h2`
font-size:2rem;
color ${vars.black}
`
