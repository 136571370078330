//Locked
import React from "react"
import { Col, Row } from "reactstrap"
import Skeleton from "react-loading-skeleton"
import styled from "styled-components"
import vars from "styles/variables"
import SummaryBar from "./SummaryBar"

function SummaryCar({ isLoading, data }) {
  if (isLoading) return <SummaryCarLoadingSkeleton />

  return (
    <>
      <StyledSectionHeader>Basic Information</StyledSectionHeader>

      <SummaryBar
        alt={1}
        label="Registration"
        value={data.registrationNumber}
      />
      <SummaryBar alt={0} label="Mileage" value={data.mileageFormatted} />
      <SummaryBar alt={1} label="Make of car" value={data.make} />
      <SummaryBar alt={0} label="Model" value={data.model} />
      <SummaryBar alt={1} label="Colour" value={data.colour} />
      <SummaryBar alt={0} label="MOT" value={data.mot} />
      <SummaryBar alt={1} label="Service History" value={data.serviceHistory} />

      <StyledSectionHeader>Additional Information</StyledSectionHeader>

      <SummaryBar alt={1} label="Additional Spec" value={data.spec} fullWidth />
      <SummaryBar alt={0} label="Transmission" value={data.transmission} />
      <SummaryBar
        alt={1}
        label="Additional Information"
        value={data.additional}
        fullWidth
      />
      <SummaryBar
        alt={0}
        label="Mechanical Faults"
        value={data.mechanicalFaults}
      />
      <SummaryBar alt={1} label="Bodywork Faults" value={data.bodyworkFaults} />

      {data.vehicleImages && data.vehicleImages.length > 0 && (
        <StyledSectionHeader>Images</StyledSectionHeader>
      )}

      <Row>
        {data.vehicleImages.map(vehicleImage => {
          return (
            <Col xs="6" key={vehicleImage.id} className="mb-3">
              <a
                href={vehicleImage.imageLocation}
                target={vehicleImage.imageCaption}
              >
                <img
                  src={vehicleImage.thumbnailLocation}
                  className="img-fluid"
                />
              </a>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default SummaryCar

function SummaryCarLoadingSkeleton() {
  return (
    <Row>
      <Col xs="12" className="mb-4">
        <Skeleton height={60} />
      </Col>
      <Col xs="12">
        <Skeleton height={300} />
      </Col>
    </Row>
  )
}

const StyledSectionHeader = styled.div`
  padding: 10px 0;
  color: ${vars.orange};
  font-weight: bold;
  margin-top: 1rem;
`
