import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react"
import { getUser, updateUser } from "services/userService"
import { toastError, toastSuccess } from "utils/toastUtil"

const Context = createContext()

// Provider component that wraps your app and makes our object
// available to any child component
export function UserProvider({ children }) {
  const value = useProvider()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

// Hook for child components to get the object
// and re-render when it changes
export const useUser = () => {
  return useContext(Context)
}

// Provider hook that creates object and handles state
function useProvider() {
  const isMounted = useRef(true)
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)

  useEffect(() => {
    onMount()
    return () => {
      isMounted.current = false
    }
  }, [])

  async function onMount() {
    const response = await getUser()

    if (!isMounted.current) return
    if (!response.isSuccess) return handleError(response.responseMessage)

    //console.log("response.user", response)

    setUser(response)
    setIsLoading(false)
  }

  function handleError(error) {
    toastError(error)
    setIsLoading(false)
  }

  async function handleUpdateUser(data) {
    setIsUpdateLoading(true)

    const response = await updateUser({
      ...data,
      id: user.id,
    })

    if (!isMounted.current) return
    if (!response.isSuccess) return handleError(response.responseMessage)

    setUser(response)
    setIsUpdateLoading(false)

    toastSuccess("User account updated")
  }

  return {
    user,
    isLoading,
    isUpdateLoading,
    handleUpdateUser,
  }
}
