import React from "react"
import { Col, Row } from "reactstrap"
import Button from "components/common/Button"
import styled from "styled-components"
import vars from "styles/variables"

function LocationsCovered() {
  return (
    <StyledBenefits>
      <Row>
        <Col xs="12" md="6">
          <a
            href="https://www.google.com/maps/place/Redgate+Lodge/@55.0280504,-1.5122062,16z/data=!4m5!3m4!1s0x487e6fd57eae2f83:0xeec75af93447901c!8m2!3d55.0287316!4d-1.5090198"
            target="maps"
          >
            <img
              src="/images/map.png"
              className="img-fluid mb-3"
              alt="Bye Buy Motors map"
            />
          </a>
        </Col>
        <Col xs="12" md="6">
          <h2 style={{ color: vars.black }}>Locations covered</h2>
          <p className="mb-4">You can take advantage of our car collection services in a wide range of locations ranging from Lancashire to Fife and everywhere in-between!</p>
          <Button styleType="black" to="#value-vehicle">
            Value Your Vehicle
          </Button>
        </Col>
      </Row>
    </StyledBenefits>
  )
}

export default LocationsCovered

const StyledBenefits = styled.div`
  padding: 20px;
  background-color: ${vars.white};
  color: ${vars.black};
`
