//Locked
import React from "react"
import { Col, Row } from "reactstrap"
import Skeleton from "react-loading-skeleton"
import SummaryBar from "./SummaryBar"
import Button from "components/common/Button"

function SummaryCustomer({ isLoading, data }) {
  if (isLoading) return <SummaryCustomerLoadingSkeleton />

  return (
    <>
      <Row>
        <Col xs="12">
          <SummaryBar alt={1} label="Full Name" value={data.fullName} />
          <SummaryBar alt={0} label="Email" value={data.email} />
          <SummaryBar alt={1} label="Post Code" value={data.postCode} />
          <SummaryBar alt={0} label="Telephone" value={data.telephone} />
          <SummaryBar
            alt={1}
            label="Preferred Contact"
            value={data.preferredContactMethod}
          />
          <SummaryBar
            alt={0}
            label="Preferred Time"
            value={data.preferredContactTime}
          />
        </Col>
      </Row>
    </>
  )
}

export default SummaryCustomer

function SummaryCustomerLoadingSkeleton() {
  return (
    <Row>
      <Col xs="12" className="mb-4">
        <Skeleton height={60} />
      </Col>
      <Col xs="12">
        <Skeleton height={300} />
      </Col>
    </Row>
  )
}
