import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import useFetch from "hooks/useFetch"
import { useForm } from "react-hook-form"
import { useParams } from "react-router"
import PageTemplate from "templates/PageTemplate"
import Button from "components/common/Button"
import {
  getValuationOffer,
  acceptValuationOffer,
  getCustomerValuationNotes,
} from "services/valuationsService"
import ValuationNotes from "./components/ValuationNotes"
import ErrorMessage from "components/core/ErrorMessage"
import TextField from "components/common/TextField"
import SummaryCar from "./components/SummaryCar"
import SummaryBar from "./components/SummaryBar"
import BenefitsList from "components/bye-buy-motors/BenefitsList"
import styled from "styled-components"
import vars from "styles/variables"
import RoundedForm from "./components/RoundedForm"

function ValuationOfferPage() {
  const { slug } = useParams()
  const [isSaving, setIsSaving] = useState(false)
  const [customerAccepting, setCustomerAccepting] = useState(null)
  const [notesReloads, setNotesReloads] = useState(0)
  const { data, setData, isLoading, error } = useFetch(() =>
    getValuationOffer(slug)
  )
  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  })

  if (error) return <ErrorMessage content={error} />

  //EVENTS

  const onChoosingCustomerAccepting = answer => {
    setCustomerAccepting(answer)
  }

  async function onSubmit(formData) {
    setIsSaving(true)
    const customerAccepted = customerAccepting === false ? false : true
    const response = await acceptValuationOffer({
      ...formData,
      guid: data.guid,
      customerAccepted: customerAccepted,
    })

    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response) //Move to hook
    }
    const newData = { ...data }
    newData.customerAccepted = customerAccepted
    setCustomerAccepting(null)
    setData(newData)
    console.log("newData", newData)
    setIsSaving(false)
    setTimeout(() => setNotesReloads(notesReloads + 1), 1000)
  }
  function handleError(error) {
    //responseError(error)
    //if (!isMounted.current) return
    setIsSaving(false)
  }
  //End of Submit Form

  //EVENTS END

  const customerResponseForm = isLoading ? null : data.staffAccepted ===
    true ? (
    //Staff has made an offer
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs="12">
          <h2 className="mb-4">Our Offer</h2>
          <div style={{ float: "left" }}>
            <h3>Offer Amount:</h3>
          </div>
          <div
            style={{
              float: "left",
              marginTop: "3px",
              marginLeft: "8px",
            }}
          >
            £{data.offerAmount}
          </div>
        </Col>
        {/* {data.offerComment && data.offerComment.length > 0 && (
          <Col xs="12">
            <SummaryBar
              alt={1}
              fullWidth
              label="Message from ByeBuyMotors"
              value={data.offerComment}
              isHtml
            />
          </Col>
        )} */}
        {data.customerAccepted === true ? (
          //Customer accepted
          <Col className="mt-4">
            {!data.customerRespondedOn ? (
              <>Thank you for confirming. We will be in touch soon.</>
            ) : (
              <>
                You accepted this offer on {data.customerRespondedOn}. Please
                call us if you have any questions.
              </>
            )}
          </Col>
        ) : //Customer rejected
        data.customerAccepted === false ? (
          <Col>
            You rejected this offer
            {!data.customerRespondedOn ? null : (
              <> on {data.customerRespondedOn}</>
            )}
            . Please call the office if you wish to discuss.
          </Col>
        ) : (
          //Customer has not yet responded
          <>
            <Col xs="12">
              <h3>Are you happy to proceed?</h3>
            </Col>

            <Col xs="6">
              <Button
                id="RejectOffer"
                styleType="red"
                onClick={() => onChoosingCustomerAccepting(false)}
                isLoading={isSaving}
                isFullWidth
                disabled={customerAccepting === false}
                style={{
                  opacity: customerAccepting === false ? ".5" : "1",
                }}
              >
                Reject Offer
              </Button>
            </Col>
            <Col xs="6">
              <Button
                id="AcceptOffer" //match
                styleType="green"
                onClick={() => onChoosingCustomerAccepting(true)}
                type="submit"
                isLoading={isSaving}
                isFullWidth
              >
                Accept Offer
              </Button>
            </Col>
          </>
        )}
      </Row>

      {customerAccepting === false ? (
        <Row>
          <Col xs="12" className="mt-3">
            <TextField
              key="customerResponseComment"
              name="customerResponseComment"
              autoFocus
              multiline
              control={control}
              label="Reason for Rejecting"
              error={formState.errors.customerResponseComment}
            />
          </Col>
          <Col xs="12" sm="6">
            <Button
              isFullWidth
              isLoading={isSaving}
              onClick={() => onChoosingCustomerAccepting(null)}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="12" sm="6">
            <Button
              type="submit"
              styleType="red"
              isFullWidth
              isLoading={isSaving}
            >
              Reject Car
            </Button>
          </Col>
        </Row>
      ) : null}
    </form>
  ) : //Staff rejected vehicle
  data.staffAccepted === false ? (
    <Row>
      <Col xs="12">
        <h2 className="mb-0">Sorry but your vehicle has been declined</h2>
      </Col>
      {/* <Col xs="12">
        <SummaryBar
          alt={1}
          fullWidth
          label="Message from ByeBuyMotors"
          value={data.offerComment}
          isHtml
        />
      </Col> */}
    </Row>
  ) : (
    //Customer waiting from response from BBM

    <Row>
      <Col xs="12">
        <h2 className="mt-3">Thank you!</h2>
      </Col>
      <Col xs="12">We will review your details and be in touch soon.</Col>
    </Row>
  )

  return (
    <PageTemplate title="Valuation Offer" content="Content" hideBorders>
      <RoundedForm fullWidth>
        {isLoading && <ValuationOfferLoadingSkeleton />}
        {!isLoading && (
          <Row className="mt-4">
            <Col xs="12" md="5">
              {customerResponseForm}
              <ValuationNotes
                guid={slug}
                mainNoteType={vars.noteType_fromCustomer}
                get={getCustomerValuationNotes}
                forceReload={notesReloads}
              />
              <Row>
                <Col xs="12" className="mt-3">
                  <WhiteH3 className="mt-3">Why sell with us?</WhiteH3>
                </Col>
                <Col xs="12" className="">
                  Because no two cars are the same, with Bye Buy Motors you can
                  save £000's, with a valuation that genuinely reflects your
                  vehicle and in particular its options, specification and
                  condition.
                </Col>
                <Col xs="12" className="">
                  <BenefitsList />
                </Col>
              </Row>
            </Col>
            <Col xs="hidden" sm="hidden" md="1"></Col>
            <Col xs="12" md="6">
              <h2>Your Car</h2>
              <SummaryCar isLoading={isLoading} data={data} />
            </Col>
          </Row>
        )}
      </RoundedForm>
    </PageTemplate>
  )
}

export default ValuationOfferPage

function ValuationOfferLoadingSkeleton() {
  return (
    <SkeletonTheme baseColor={vars.grey_600} highlightColor={vars.grey_700}>
      <Row className="mt-5">
        <Col xs="12" md="6" className="mb-4">
          <Skeleton height={100} />
        </Col>
        <Col xs="12" md="6">
          <Row>
            <Col xs="12">
              <Skeleton height={300} className="mb-4" />
            </Col>
            <Col xs="12">
              <Skeleton height={300} />
            </Col>
          </Row>
        </Col>
      </Row>
    </SkeletonTheme>
  )
}

const WhiteH3 = styled.h3`
  color: ${vars.white};
`
