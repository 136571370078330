import React from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import vars from "styles/variables"
import LoadingIcon from "components/common/LoadingIcon"

const Button = ({
  children,
  isLoading,
  styleType = "primary",
  outline = null,
  type = "button",
  to,
  isFullWidth,
  isLarge,
  ...other
}) => {
  if (!to) {
    // No property for 'to' has been passed, return a form button
    return (
      <StyledButtonContainer isFullWidth={isFullWidth}>
        <StyledButton
          styleType={styleType}
          outline={outline}
          type={type}
          isLoading={isLoading}
          disabled={isLoading}
          isFullWidth={isFullWidth}
          isLarge={isLarge}
          {...other}
        >
          <span className="button-text">{children}</span>
          <StyledLoadingContainer isLoading={isLoading}>
            <LoadingIcon />
          </StyledLoadingContainer>
        </StyledButton>
      </StyledButtonContainer>
    )
  } else {
    // The 'to' property has a value, retrun an internal or external link.
    // The below regex assumes that any internal link will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)

    if (internal) {
      return (
        <StyledButtonContainer isFullWidth={isFullWidth}>
          <StyledButton
            as={({ styleType, isLoading, isFullWidth, isLarge, ...rest }) => (
              <Link {...rest} />
            )}
            to={to}
            styleType={styleType}
            outline={outline}
            isFullWidth={isFullWidth}
            isLoading={isLoading}
            isLarge={isLarge}
            {...other}
          >
            {children}
          </StyledButton>
        </StyledButtonContainer>
      )
    } else {
      //Note that the loading indicator doesn't work for this type
      return (
        <StyledButtonContainer isFullWidth={isFullWidth}>
          <StyledButton
            as="a"
            href={to}
            styleType={styleType}
            outline={outline}
            isFullWidth={isFullWidth}
            isLoading={isLoading}
            isLarge={isLarge}
            {...other}
          >
            {children}
          </StyledButton>
        </StyledButtonContainer>
      )
    }
  }
}

export default Button

const StyledButtonContainer = styled.div`
  display: inline-block;
  position: relative;
  ${props => (props.isFullWidth ? "width:100%;" : "")}

  &:after {
    content: "";
    width: calc(100% - 20px);
    height: 32px;
    background: ${vars.blackTransparent(0.3)};
    position: absolute;
    top: 14px;
    left: 10px;
    z-index: 1;
    filter: blur(8px);
  }
`

const ButtonStyle = css`
  font-size: ${props => (props.isLarge ? "16px" : "14px")};
  height: ${props => (props.isLarge ? "42px" : "30px")};
  ${props => (props.isFullWidth ? "width:100%;" : "")}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: ${props => (props.isLarge ? "1.8rem" : "1.5rem")} 1.2rem;
  ${props =>
    props.outline ? "border: solid 1px " + vars.orange + " !important;" : ""}
  cursor: pointer;
  line-height: 1;
  border-radius: 4px;
  color: ${props =>
    props.outline
      ? vars.white
      : props.styleType === "black"
      ? vars.white
      : props.styleType === "grey"
      ? vars.grey_400
      : props.styleType === "green"
      ? vars.white
      : props.styleType === "red"
      ? vars.white
      : vars.black};
  opacity: 1;
  transition: ease-in-out 200ms;
  background: ${props =>
    props.outline
      ? vars.black
      : props.styleType === "black"
      ? vars.grey_700
      : props.styleType === "grey"
      ? vars.grey_600
      : props.styleType === "green"
      ? vars.green
      : props.styleType === "red"
      ? vars.red
      : vars.primary};
  font-family: ${vars.secondaryFont};
  font-weight: bold;
  position: relative;
  user-select: none;
  z-index: 10;

  .button-text {
    visibility: ${props => (props.isLoading ? "hidden" : "visible")};
  }

  &:hover,
  &:disabled,
  &[disabled] {
    background: ${props =>
      props.styleType === "black" ? vars.grey_600 : vars.primaryDark};
  }
`

const StyledButton = styled(
  ({ styleType, isLoading, isFullWidth, isLarge, outline, ...rest }) => (
    <button {...rest} />
  )
)`
  ${ButtonStyle}
`
const StyledLoadingContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: ${props => (props.isLoading ? "flex" : "none")};
`
