import React from "react"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import { Col, Row } from "reactstrap"
import vars from "styles/variables"

const TextField = React.forwardRef((props, ref) => {
  const {
    control,
    label,
    sublabel,
    placeholder,
    error,
    defaultValue,
    name,
    rules,
    required,
    margin = "0 0 1.5rem 0",
    multiline,
    ...rest
  } = props

  return (
    <StyledTextFieldContainer margin={margin}>
      <Row className="justify-content-between">
        {label && (
          <Col xs="auto">
            <label
              style={{ fontWeight: "bold" }}
              className={(!!sublabel ? "" : "mb-1") + " d-inline-block"}
            >
              {label}
            </label>
          </Col>
        )}

        {error && (
          <Col xs="auto" className="text-danger mb-1">
            <div className="overflow-hidden">
              <div className="animate-slide-in-right">
                {error.type === "required" ? "Required" : error.message}
              </div>
            </div>
          </Col>
        )}
        {sublabel && (
          <Col xs="12">
            <label className="mb-1 d-inline-block">{sublabel}</label>
          </Col>
        )}
      </Row>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required, ...rules }}
        render={({ field: { onChange, onBlur, value } }) => (
          <StyledTextField
            ref={ref}
            as={multiline ? "textarea" : "input"}
            rows={6}
            type="text"
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={value => onChange(value)}
            value={value || ""}
            error={error}
            multiline={multiline}
            {...rest}
          />
        )}
      />
    </StyledTextFieldContainer>
  )
})

export default TextField

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  required: PropTypes.bool,
  transparent: PropTypes.bool,
}

const StyledTextFieldContainer = styled.div`
  margin: ${props => props.margin};
`

const StyledTextField = styled.input`
  border: 1px solid
    ${props => (props.error ? `${vars.red} !important` : vars.black)};
  padding: 10px;
  width: 100%;
  padding: 10px;
  ${props => !props.multiline && "height: 50px;"}
  font-family: ${vars.primaryFont};
  font-size: 1rem;
  transition: ease-in-out 300ms;

  &:focus {
    border-color: ${vars.primary};
  }

  &::placeholder {
    color: ${vars.grey_500};
    opacity: 1;
  }
`
