import * as React from "react"

const FacebookIcon = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={11.5} stroke="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.183 7.502 13.016 7.5c-1.312 0-2.16.87-2.16 2.215v1.021H9.684a.183.183 0 0 0-.183.184v1.48c0 .1.082.183.183.183h1.174v3.734c0 .1.082.183.184.183h1.53a.184.184 0 0 0 .184-.183v-3.734h1.372a.183.183 0 0 0 .183-.183l.001-1.48a.183.183 0 0 0-.184-.183h-1.372V9.87c0-.416.1-.627.641-.627h.786a.183.183 0 0 0 .184-.184V7.686a.184.184 0 0 0-.183-.184Z"
      fill="#fff"
    />
  </svg>
)

export default FacebookIcon
