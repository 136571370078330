import config from "config"
import httpService from "services/httpService"

const endpoint = `${config.api}/User`

export async function getUser() {
    const data = await httpService.get(`${endpoint}/User`)
    return data
}

export async function updateUser(user) {
    const data = await httpService.post(`${endpoint}/UpdateUser`, user)
    return data
}
