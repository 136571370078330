//Locked
import React from "react"
import { useHistory } from "react-router-dom"
import { Col } from "reactstrap"
import Button from "components/common/Button"
import NeedHelp from "components/bye-buy-motors/NeedHelp"
import styled from "styled-components"
import vars from "styles/variables"

function NextBackButtons({
  disabledMessage,
  isLoading,
  children = "Next",
  to,
  styleType,
}) {
  const history = useHistory()

  return (
    <>
      <Col xs="12">
        <StyledRule></StyledRule>
      </Col>

      <Col xs="6" sm="4" md="3">
        <Button
          id="Back"
          isFullWidth={true}
          onClick={() => history.goBack()}
          isLoading={isLoading}
          isLarge={true}
          styleType="grey"
        >
          Back
        </Button>
      </Col>
      <Col xs="6" sm="8" md="9">
        <Button
          id="Next"
          type={!to ? "submit" : ""}
          to={!disabledMessage ? to : null}
          isFullWidth={true}
          isLoading={isLoading}
          disabled={!disabledMessage ? null : "disabled"}
          styleType={styleType}
          isLarge={true}
          style={{ cursor: !disabledMessage ? "pointer" : "not-allowed" }}
          title={disabledMessage}
        >
          {children}
        </Button>
      </Col>

      <Col>
        <NeedHelp />
      </Col>
    </>
  )
}

export default NextBackButtons

const StyledRule = styled.div`
  width: 100%;
  border-top: solid;
  border-color: ${vars.grey_600};
  margin-bottom: 18px;
`
