import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import * as Sentry from "@sentry/react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "bootstrap-4-grid/css/grid.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "@material/react-ripple/dist/ripple.css"
import "react-loading-skeleton/dist/skeleton.css"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { muiTheme } from "utils/themeUtil"
import config from "config"
import { ArticleProvider } from "hooks/useArticles"
import { RefreshProvider } from "hooks/useRefresh"
import { UserProvider } from "hooks/useUser"
import { initTagManager } from "services/homeService"
import { ValuationProvider } from "hooks/useValuations"

initTagManager()

Sentry.init(config.sentry)

const theme = createTheme(muiTheme)

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <ArticleProvider>
        <ValuationProvider>
          <RefreshProvider>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <App />
                <ToastContainer />
              </BrowserRouter>
            </ThemeProvider>
          </RefreshProvider>
        </ValuationProvider>
      </ArticleProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

serviceWorker.unregister()
