import * as React from "react"

const TickIcon = (props) => (
  <svg
    width={9}
    height={7}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.125 4.911 1.213 3l-.65.646 2.562 2.562 5.5-5.5-.647-.646-4.853 4.85Z"
      fill="#000"
    />
  </svg>
)

export default TickIcon
