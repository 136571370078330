import axios from "axios"
import logger from "services/logService"

// This interceptor handles unexpected errors and logs them, this only needs to be setup once
// and any unexpected errors will be logged.
axios.interceptors.response.use(null, error => {
  // Response returned is forbidden
  //! We are redirecting using js and not within React, this is a non blocking operation
  //! so the forbidden page shows (without any data as it was not returned from the server) until
  //! the js redirects the page. Tried to use react-router-dom to redirect outside of a component but
  //! the routing would break and you can't navigate to pages. For now this solution will have to do, until
  //! we work on a better solution. We could return data down to the component and redirect there, but that means
  //! adding the redirect to every component which is no good.
  if (error.response.status === 403) {
    window.location.href = "/forbidden"

    //! Temp solution to stop the forbidden page shown, just use js to remove the whole app
    //! while the page is in the process of redirecting. It's not pretty but it seems to work.
    const root = document.getElementById("root")
    root.remove()
  }

  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500

  if (!expectedError) {
    // If this is an unexpected error then log it.
    logger.error(error)
    //? Not sure if we need to call a toast here as we usually handle error messages in the component
    //toast.error("An unexpected error occured.")
  }

  return Promise.reject(error)
})

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt
}

//Assumes the calls always return a responseType and responseMessage
//Catches server errors and puts into responseType and responseMessage
//This means you only need to handle errors by inspecting those values (no try-catch needed)
async function axiosCall(type, fn, url, data, config, skipResponseCheck) {
  try {
    console.log("Axios Request", url, config, data)
    let response

    if (type === "GET" || type === "DEL") response = await fn(url, config)
    else response = await fn(url, data, config)

    console.log("Axios Response", response.data || response)
    if (skipResponseCheck) return response
    return checkResponse(response)
  } catch (error) {
    console.error("Axios Error", error)
    return {
      responseType: "EXCEPTION",
      responseMessage: String(error.message || error),
    }
  }
}

function checkResponse(response) {
  if (response.data && response.data.responseType)
    return {
      ...response.data,
      // Added an isSuccess property to each response object so we don't have to
      // keep checking the response type is equal to SUCCESS in every component
      isSuccess: response.data.responseType === "SUCCESS" ? true : false,
      is404: response.data.responseType === "NOTFOUND" ? true : false,
    }

  //! Ideally the api would return a 401 so we can check the response code
  //! and trigger the below, for now we have to check the response data and if html has been
  //! returned we assume the user is no longer authenticated.
  if (response.data.indexOf("<html>") > -1) {
    window.location.href =
      "/account/login?returnUrl=" +
      window.location.pathname +
      window.location.search
  }

  return {
    isSuccess: false,
    is404: response.status === 204 || response.status === 404,
    responseType: "EXCEPTION",
    responseMessage:
      response.data.indexOf("<html>") > -1
        ? "logout"
        : response.status +
          ": " +
          (!response.statusText || response.statusText === "undefined"
            ? "Something went wrong"
            : response.statusText),
  }
}

async function get(url, config, skipResponseCheck) {
  return axiosCall("GET", axios.get, url, null, config, skipResponseCheck)
}

async function post(url, data, config, skipResponseCheck) {
  return axiosCall("POST", axios.post, url, data, config, skipResponseCheck)
}

async function put(url, data, config, skipResponseCheck) {
  return axiosCall("PUT", axios.put, url, data, config, skipResponseCheck)
}

async function del(url, config, skipResponseCheck) {
  return axiosCall("DEL", axios.delete, url, null, config, skipResponseCheck)
}

export default {
  get,
  post,
  put,
  del,
  setJwt,
}
