import React from "react"
import styled from "styled-components"
import Footer from "components/core/Footer"
import FooterBasic from "components/core/FooterBasic"
import FooterAdmin from "components/core/FooterAdmin"

function PageTemplate({
  children,
  hideFooter,
  adminFooter,
  basicFooter,
  hideBorders,
}) {
  return (
    <>
      {!hideBorders && <hr className="my-4" />}
      <StyledPageTemplateContent>{children}</StyledPageTemplateContent>
      {hideFooter ? null : adminFooter ? (
        <FooterAdmin />
      ) : basicFooter ? (
        <FooterBasic />
      ) : (
        <>
          {!hideBorders && <hr className="mb-4" />}
          <Footer />
        </>
      )}
    </>
  )
}

export default PageTemplate

const StyledPageTemplateContent = styled.div`
  min-height: 300px;
`
