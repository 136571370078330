import vars from "styles/variables"
import { createGlobalStyle, css } from "styled-components"

const styles = css`
  *,
  *:focus,
  *:hover {
    outline: none;
  }

  body {
    margin: 0;
    font-family: ${vars.primaryFont};
    background: ${vars.black};
    color: ${vars.white};
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
  }

  .nav-open {
    overflow: hidden;
  }

  textarea {
    font-family: ${vars.primaryFont};
  }

  main {
    flex-grow: 1;
  }

  h1 {
    margin-top: 0;
    text-transform: uppercase;
  }

  h2,
  h3 {
    margin-top: 0;
    color: ${vars.orange};
  }

  a {
    text-decoration: none;
    color: ${vars.black};
  }

  p {
    margin-top: 0;
  }

  .error-text {
    color: ${vars.red};
  }

  .full-width,
  .img-fluid {
    width: 100%;
  }

  .img-circle {
    border-radius: 50%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .heading {
    border-left: 16px solid transparent;
    padding-left: 8px;
    position: relative;

    :before {
      content: "";
      width: 16px;
      height: calc(100% - 10px);
      top: 5px;
      left: -16px;
      position: absolute;
      background: ${vars.primary};
    }
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
    margin: 0;
  }

  .slick-slide div {
    outline: none;
  }

  hr {
    border: none;
    border-bottom: 1px solid ${vars.grey_500};
  }

  .box-hover {
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* display: block; */
      transition: ease-in-out 500ms;
    }

    &:hover {
      color: ${vars.white};

      .img {
        filter: blur(3px);
        transform: scale(1.1);
      }
    }
  }

  .react-loading-skeleton::after {
    top: 0;
  }

  ${[1, 2, 3, 4, 5].map(
  x => `.bg-grey_${x}00 { background-color: ${vars[`grey_${x}00`]}; }`
)}

  .sticky {
    position: sticky;
    top: 1.5rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .animate-slide-down {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);

    animation: slide-in-horizontal 0.5s forwards;
    -webkit-animation: slide-in-horizontal 0.5s forwards;
  }

  .animate-slide-in-left {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);

    animation: slide-in-vertical 0.5s forwards;
    -webkit-animation: slide-in-vertical 0.5s forwards;
  }

  .animate-slide-in-right {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);

    animation: slide-in-vertical 0.5s forwards;
    -webkit-animation: slide-in-vertical 0.5s forwards;
  }

  @keyframes slide-in-vertical {
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-in-horizontal {
    100% {
      transform: translateX(0%);
    }
  }

  .animate-fade-in {
    animation: fade-in 0.5s forwards;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .max-width-500 {
    max-width: 500px;
  }

  .margin-center {
    margin-left: auto;
    margin-right: auto;
  }

  .primary-button {
    font-size: 16px;
    height: 42px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
    padding: 0px 1.5rem;
    cursor: pointer;
    line-height: 1;
    border: none;
    color: rgb(255, 255, 255);
    opacity: 1;
    transition: all 200ms ease-in-out 0s;
    background: rgb(0, 168, 216);
    border-radius: 0px;
    font-family: sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    position: relative;
    user-select: none;
    z-index: 10;

    &:hover,
    &:disabled,
    &[disabled] {
      background: ${vars.primaryDark};
    }
  }
`

export default createGlobalStyle`
  ${styles}
`
