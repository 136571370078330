import React from "react"
import Layout from "components/core/Layout"
import Routing from "components/core/Routing"
import ErrorBoundary from "components/core/ErrorBoundary"

function App() {
  return (
    <ErrorBoundary>
      <Layout>
        <Routing />
      </Layout>
    </ErrorBoundary>
  )
}

export default App
